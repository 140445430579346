import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import GoalEnum from '../../enums/GoalEnum'
import {
  checkEmpty,
  checkNoneEmpty,
  checkNotEmpty,
  isUndefinedOrNull,
} from '../regexUtils'
import { List } from 'immutable'
import AdGroupConstraints from './constraints-adGroup'

const CampaignConstraints = {
  isKclidRequired({ objectiveType, kclidConstraints }) {
    if (checkNoneEmpty(objectiveType, kclidConstraints)) {
      // 광고 목표 설정 유형
      const { required, available } = kclidConstraints.find(
        ({ objectiveType: type }) => type === objectiveType
      )

      return required || available
    } else if (checkNotEmpty(kclidConstraints)) {
      // 광고 목표 설정을 하지 않는 유형
      const { required, available } = kclidConstraints.first()
      return required || available
    }

    // 메시지 캠페인 유형 및 전환 (TALK_CHANNEL type)
    return false
  },

  // 전환 추적
  isConversionRequired({
    campaignType,
    goal,
    trackConstraints,
    objectiveType,
  }) {
    if (
      (campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED &&
        goal === GoalEnum.Type.REACH) ||
      (campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG &&
        goal === GoalEnum.Type.CONVERSION) ||
      (campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE &&
        goal === GoalEnum.Type.REACH)
    ) {
      return true
    } else {
      if (checkNotEmpty(trackConstraints)) {
        const isObjectiveRequired = AdGroupConstraints.isObjectiveRequired({
          campaignType,
          goal,
        })

        const { required = false, available = false } =
          trackConstraints
            .filter(v =>
              isObjectiveRequired
                ? v.get('objectiveType') === objectiveType
                : true
            )
            .first() || {}

        return required || available
      }

      return false
    }
  },

  // 광고목표 설정
  isAvailableObjectiveTypes({ constraint, objective, campaignType }) {
    if (
      campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG ||
      campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE
    ) {
      // 고정값
      const { type: objectiveType } = objective || {}
      return Array.of(objectiveType)
    }

    if (checkEmpty(constraint) || !constraint.size) return List()

    return constraint.groupBy(v => v.get('objectiveType')).keySeq()
  },

  // 일예산
  isAvailableDailyBudget({ campaignType, goal, constraint }) {
    if (
      campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG &&
      goal === GoalEnum.Type.CONVERSION
    ) {
      return true
    } else {
      if (checkNotEmpty(constraint)) {
        return Object.keys(constraint.toJS()).every(
          k => !isUndefinedOrNull(constraint.get(k))
        )
      }

      return false
    }
  },

  /**
   * 전환추적 선택시 detailType 초기화 여부
   */
  isObjectiveDetailTypeClearRequired({ campaignType }) {
    return (
      campaignType !== CampaignTypeEnum.Type.PRODUCT_CATALOG &&
      campaignType !== CampaignTypeEnum.Type.TALK_CHANNEL &&
      campaignType !== CampaignTypeEnum.Type.PERSONAL_MESSAGE
    )
  },

  /** 광고 계약 선택 */
  isAdProductSetRequired({ campaignType }) {
    return [
      CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED,
      CampaignTypeEnum.Type.PC_TALK_RICH_POP,
      CampaignTypeEnum.Type.PC_TALK_BOTTOM,
      CampaignTypeEnum.Type.FOCUS_FULL_VIEW,
    ].includes(campaignType)
  },
}

export default CampaignConstraints
