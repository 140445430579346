import React from 'react'
import {
  hideLoading,
  LOADING_KEY,
  showLoading,
} from '../../modules/common/mLoading'
import {
  deleteAllDashboardTableCheckedRowIds,
  getDashboardTableCampaignRows,
  selectDashboardTable,
  setDashboardTableCheckedRowIds,
} from '../../modules/dashboardV3/mDashboardTable'
import DashboardEnumV2 from '../../enums/DashboardEnumV2'
import {
  selectDashboardSummary,
  unselectDashboardSummary,
} from '../../modules/dashboardV3/mDashboardSummary'
import {
  showErrorMessage,
  showLoadingMessage,
  showPromiseMessage,
} from '../../utils/alertUtils'
import GoalEnum from '../../enums/GoalEnum'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import { AdvertiseRouterV2 } from '../../utils/advertise/advertiseRouterV2'
import {
  openPopup,
  openPopupByProxy,
  POPUP_KEY,
} from '../../modules/common/mPopup'
import { exceedCampaignOfAdAccountDialog } from '../../components/DashboardV2/Common/Dialog/DashboardDialog'
import { DashboardToastMessage } from '../../utils/dashboard/dashboardToastMessage'
import { DashboardUtils } from '../../components/DashboardV3/dashboardUtils'
import {
  campaignDailyBudgetConfirmDialog,
  campaignDailyBudgetModifyWarningDialog,
  campaignPauseWarningDialog,
  campaignResumeWarningForTalkChannelReachDialog,
} from '../../components/DashboardV3/Dialog/CampaignDialog'
import RequestLock from '../../utils/requestLock'
import { toast } from 'react-hot-toast'
import { getDashboardExceptionMessage } from '../../modules/dashboardV3/dashboardException'
import { MomentToasterButton } from '../../components/MomentToaster/MomentToaster'
import { checkEmpty, checkNotEmpty } from '../../utils/regexUtils'
import { hideDashboardOnOffSpinnerLoading } from '../../modules/dashboardV3/mDashboardCommon'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'

/**
 * 테이블 > 항목 선택(체크)
 */
export function selectDashboardCampaignTable({ id }) {
  return dispatch => {
    dispatch(
      setDashboardTableCheckedRowIds({
        dashboardType: DashboardEnumV2.Type.CAMPAIGN,
        ids: [id],
      })
    )
    dispatch(
      selectDashboardTable({ dashboardType: DashboardEnumV2.Type.AD_GROUP })
    )
  }
}

/**
 * 테이블 > 항목 선택(정보 & 차트)
 */
export function selectDashboardSummaryCampaignByCell({ id = undefined }) {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        summary: { dashboardType: prevDashboardType, id: prevId },
      },
    } = getState()

    const unselect = DashboardUtils.isSummarySelected({
      dashboardType: DashboardEnumV2.Type.CAMPAIGN,
      id,
      prevDashboardType,
      prevId,
    })

    if (unselect) {
      dispatch(unselectDashboardSummary())
    } else {
      dispatch(
        selectDashboardSummary({
          dashboardType: DashboardEnumV2.Type.CAMPAIGN,
          id,
        })
      )
    }
  }
}

/**
 * 만들기 진입
 */
export function goToCreateCampaign({ adAccountId, ...rest }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    try {
      await api.dashboard.validateCampaignCount(adAccountId)

      dispatch(
        RouterV2.push(
          AdvertiseRouterV2.Path.getCampaignAndGroupPageV2(adAccountId, {
            create: true,
            ...rest,
          })
        )
      )
    } catch (e) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          exceedCampaignOfAdAccountDialog()
        )
      )
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * ON/OFF 변경 전 체크
 */
function checkOnOffEffectCampaigns({
  adAccountId,
  ids,
  active,
  loadingMessage,
  onOK,
}) {
  return async (dispatch, getState, api) => {
    const toastId = showLoadingMessage(loadingMessage)

    try {
      await RequestLock.acquire({
        key: 'checkOnOffEffectCampaigns',
        executor: async done => {
          try {
            dispatch(
              showLoading(LOADING_KEY.DASHBOARD_V3_ON_OFF_PRE_CHECK_CAMPAIGN)
            )

            const response = await api.dashboard.preCheckCampaignsOnOff(
              adAccountId,
              ids,
              active ? 'ON' : 'OFF'
            )

            const campaignTypeGoals = response?.data || []

            const has = ({ campaignType, goal }) =>
              campaignTypeGoals.some(v => {
                const a = v.campaignType === campaignType
                const b = v.goal === goal
                if (campaignType && goal) return a && b
                if (campaignType && !goal) return a
                if (!campaignType && goal) return b
                return false
              })

            const hasTalkChannelReach = has({
              campaignType: CampaignTypeEnum.Type.TALK_CHANNEL,
              goal: GoalEnum.Type.REACH,
            })

            const hasDaumShoppingReach = has({
              campaignType: CampaignTypeEnum.Type.DAUM_SHOPPING,
              goal: GoalEnum.Type.REACH,
            })

            const hasTalkBizBoardReserved = has({
              campaignType: CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED,
              goal: GoalEnum.Type.REACH,
            })

            const hasPersonalMessageReach = has({
              campaignType: CampaignTypeEnum.Type.PERSONAL_MESSAGE,
              goal: GoalEnum.Type.REACH,
            })

            const hasElection2024 = has({
              campaignType: CampaignTypeEnum.Type.ELECTION_2024_04,
              goal: GoalEnum.Type.REACH,
            })

            const hasPcTalkBottom = has({
              campaignType: CampaignTypeEnum.Type.PC_TALK_BOTTOM,
              goal: GoalEnum.Type.REACH,
            })

            const hasPcTalkRichPop = has({
              campaignType: CampaignTypeEnum.Type.PC_TALK_RICH_POP,
              goal: GoalEnum.Type.REACH,
            })

            const hasFocusFullView = has({
              campaignType: CampaignTypeEnum.Type.FOCUS_FULL_VIEW,
              goal: GoalEnum.Type.REACH,
            })

            if (active) {
              if (hasTalkChannelReach) {
                dispatch(
                  openPopupByProxy(
                    POPUP_KEY.SIMPLE_POPUP,
                    campaignResumeWarningForTalkChannelReachDialog({
                      onOK: () => onOK({ toastId }),
                      onCancel: () =>
                        dispatch(hideDashboardOnOffSpinnerLoading()),
                    })
                  )
                )

                toast.remove(toastId)
                return
              }
            } else {
              if (
                hasTalkChannelReach ||
                hasDaumShoppingReach ||
                hasTalkBizBoardReserved ||
                hasPersonalMessageReach ||
                hasElection2024 ||
                hasPcTalkBottom ||
                hasPcTalkRichPop ||
                hasFocusFullView
              ) {
                dispatch(
                  openPopupByProxy(
                    POPUP_KEY.SIMPLE_POPUP,
                    campaignPauseWarningDialog({
                      onOK: () => onOK({ toastId }),
                      onCancel: () =>
                        dispatch(hideDashboardOnOffSpinnerLoading()),
                      hasTalkChannelReach,
                      hasDaumShoppingReach,
                      hasTalkBizBoardReserved,
                      hasPersonalMessageReach,
                      hasElection2024,
                      hasPcTalkBottom,
                      hasPcTalkRichPop,
                      hasFocusFullView,
                    })
                  )
                )
                toast.remove(toastId)
                return
              }
            }

            onOK({ toastId })
          } catch (e) {
            console.log(e.message)

            if (checkNotEmpty(toastId)) {
              toast.remove(toastId)
            }
          } finally {
            dispatch(
              hideLoading(LOADING_KEY.DASHBOARD_V3_ON_OFF_PRE_CHECK_CAMPAIGN)
            )

            done()
          }
        },
      })
    } catch (e) {
      if (e?.isLocked) {
        console.warn('checkOnOffEffectCampaigns is locked')
      }
    }
  }
}

/**
 * 테이블 > ON/OFF 스위치
 */
export function modifyCampaignOnOff({ adAccountId, id, active = false }) {
  return (dispatch, getState, api) => {
    const subject = '캠페인 ON/OFF'
    const loadingMessage = DashboardToastMessage.modifyLoading(subject)

    const hasAdGroupSendingDetail = ({ successCount, failureCount }) =>
      successCount + failureCount > 0

    dispatch(
      checkOnOffEffectCampaigns({
        adAccountId,
        ids: [id],
        active,
        loadingMessage,
        onOK: async ({ toastId }) => {
          try {
            await showPromiseMessage({
              existToastId: toastId,
              promiseFn: () =>
                api.dashboard.modifyCampaignOnOff(adAccountId, id, active),
              onLoading: () => {
                return loadingMessage
              },
              onSuccess: response => {
                /**
                 * 현재 탭 여부와 관계없이 무조건 테이블 갱신한다.
                 * - 그룹/소재 탭 모두 캠페인 하위 레벨이므로 갱신 필요함.
                 */
                dispatch(getDashboardTableCampaignRows({ adAccountId }))

                // 그룹 메시지 상태 변경 정보
                const adGroupSendingResult = response.data || {}

                if (hasAdGroupSendingDetail(adGroupSendingResult)) {
                  return DashboardToastMessage.changeOnOffAdGroupSendingStatusResult(
                    adGroupSendingResult
                  )
                } else {
                  return DashboardToastMessage.modifySuccess(subject)
                }
              },
              renderButtonOnSuccess: response => {
                /**
                 * requests id[]
                 * successes: {
                 *    [id]: object
                 * }
                 * failures {
                 *    [id]: { errorCode: number, errorMessage: string }
                 * }
                 * successCount {number}
                 * failureCount {number}
                 */
                const adGroupSendingResult = response.data || {}

                if (hasAdGroupSendingDetail(adGroupSendingResult)) {
                  return (
                    <MomentToasterButton
                      label="자세히 보기"
                      onClick={() => {
                        dispatch(
                          openPopup(
                            POPUP_KEY.DASHBOARD_PROMISE_MESSAGE_DETAIL,
                            {
                              requestName: '광고그룹 발송 상태 변경',
                              resultIdContextName: '광고그룹',
                              ...adGroupSendingResult,
                            }
                          )
                        )
                      }}
                    />
                  )
                }

                return null
              },
              onError: e => {
                return (
                  getDashboardExceptionMessage({ e }) ||
                  DashboardToastMessage.modifyFailure(subject)
                )
              },
              onFinally: () => {
                dispatch(hideDashboardOnOffSpinnerLoading())
              },
            })
          } catch (e) {
            showErrorMessage(
              getDashboardExceptionMessage({ e }) ||
                DashboardToastMessage.modifyFailure(subject)
            )
            console.log(e.message)
          }
        },
      })
    )
  }
}

/**
 * 테이블 헤더 > ON/OFF 버튼
 */
export function modifyCampaignOnOffBulk({ adAccountId, ids, active = false }) {
  return (dispatch, getState, api) => {
    const subject = '캠페인 ON/OFF'
    const loadingMessage = DashboardToastMessage.modifyLoading(subject)

    const hasAdGroupSendingDetail = ({ successCount, failureCount }) =>
      successCount + failureCount > 0

    dispatch(
      checkOnOffEffectCampaigns({
        adAccountId,
        ids,
        active,
        loadingMessage,
        onOK: async ({ toastId }) => {
          try {
            await showPromiseMessage({
              existToastId: toastId,
              promiseFn: () =>
                api.dashboard.modifyCampaignOnOffBulk(adAccountId, ids, active),
              onLoading: () => {
                dispatch(
                  showLoading(LOADING_KEY.DASHBOARD_V3_ON_OFF_BULK_CAMPAIGN)
                )

                return loadingMessage
              },
              onSuccess: response => {
                /**
                 * 현재 탭 여부와 관계없이 무조건 테이블 갱신한다.
                 * - 그룹/소재 탭 모두 캠페인 하위 레벨이므로 갱신 필요함.
                 */
                dispatch(getDashboardTableCampaignRows({ adAccountId }))

                // 전체 선택 해제
                dispatch(
                  deleteAllDashboardTableCheckedRowIds({
                    dashboardType: DashboardEnumV2.Type.CAMPAIGN,
                  })
                )

                // 하위 그룹 메시지 상태 변경 정보
                const adGroupSendingResult = response.data || {}
                if (hasAdGroupSendingDetail(adGroupSendingResult)) {
                  return DashboardToastMessage.changeOnOffAdGroupSendingStatusResult(
                    adGroupSendingResult
                  )
                } else {
                  return DashboardToastMessage.modifySuccess(subject)
                }
              },
              renderButtonOnSuccess: response => {
                /**
                 * requests id[]
                 * successes: {
                 *    [id]: object
                 * }
                 * failures {
                 *    [id]: { errorCode: number, errorMessage: string }
                 * }
                 * successCount {number}
                 * failureCount {number}
                 */
                const adGroupSendingResult = response.data || {}

                if (hasAdGroupSendingDetail(adGroupSendingResult)) {
                  return (
                    <MomentToasterButton
                      label="자세히 보기"
                      onClick={() => {
                        dispatch(
                          openPopup(
                            POPUP_KEY.DASHBOARD_PROMISE_MESSAGE_DETAIL,
                            {
                              requestName: '광고그룹 발송 상태 변경',
                              resultIdContextName: '광고그룹',
                              ...adGroupSendingResult,
                            }
                          )
                        )
                      }}
                    />
                  )
                }

                return null
              },
              onError: e => {
                return (
                  getDashboardExceptionMessage({ e }) ||
                  DashboardToastMessage.modifyFailure(subject)
                )
              },
              onFinally: () => {
                dispatch(
                  hideLoading(LOADING_KEY.DASHBOARD_V3_ON_OFF_BULK_CAMPAIGN)
                )
              },
            })
          } catch (e) {
            showErrorMessage(
              getDashboardExceptionMessage({ e }) ||
                DashboardToastMessage.modifyFailure(subject)
            )
            console.log(e.message)
          }
        },
      })
    )
  }
}

/**
 * 테이블 헤더 > 삭제 버튼
 */
export function deleteCampaignBulk({ adAccountId, ids }) {
  return async (dispatch, getState, api) => {
    const subject = '캠페인 삭제'

    const getErrorMessage = ({ e }) => {
      const { errorCode } = e.response?.data || {}
      return [28005, 32040].includes(errorCode)
        ? '기간이 유효한 광고그룹이 있는 캠페인은 삭제할 수 없습니다. 하위 모든 광고그룹 집행기간이 종료되거나, 계약해지된 캠페인만 삭제할 수 있습니다.'
        : ''
    }

    try {
      await showPromiseMessage({
        promiseFn: () => api.dashboard.deleteCampaignBulk(adAccountId, ids),
        onLoading: () => {
          dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_DELETE_BULK))

          return DashboardToastMessage.modifyLoading(subject)
        },
        onSuccess: () => {
          dispatch(getDashboardTableCampaignRows({ adAccountId }))

          return DashboardToastMessage.modifySuccess(subject)
        },
        onError: e => {
          return (
            getErrorMessage({ e }) ||
            getDashboardExceptionMessage({ e }) ||
            DashboardToastMessage.modifyFailure(subject)
          )
        },
        onFinally: () => {
          dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_DELETE_BULK))
        },
      })
    } catch (e) {
      showErrorMessage(
        getDashboardExceptionMessage({ e }) ||
          DashboardToastMessage.modifyFailure(subject)
      )
      console.log(e.message)
    }
  }
}

/**
 * 테이블 > 일 예산 수정
 */
export function modifyCampaignDailyBudget({
  adAccountId,
  id,
  dailyBudget,
  prevDailyBudget,
  onStart,
}) {
  return (dispatch, getState, api) => {
    const onSubmit = async () => {
      const subject = '캠페인 일 예산'

      try {
        await showPromiseMessage({
          promiseFn: () =>
            api.dashboard.modifyCampaignDailyBudget(
              adAccountId,
              id,
              dailyBudget
            ),
          onLoading: () => {
            if (typeof onStart === 'function') {
              onStart()
            }

            return DashboardToastMessage.modifyLoading(subject)
          },
          onSuccess: () => {
            dispatch(getDashboardTableCampaignRows({ adAccountId }))

            return DashboardToastMessage.modifySuccess(subject)
          },
          onError: e => {
            return (
              getDashboardExceptionMessage({ e }) ||
              DashboardToastMessage.modifyFailure(subject)
            )
          },
        })
      } catch (e) {
        showErrorMessage(
          getDashboardExceptionMessage({ e }) ||
            DashboardToastMessage.modifyFailure(subject)
        )
        console.log(e.message)
      }
    }

    const onOK = () => {
      if (checkNotEmpty(dailyBudget) && checkEmpty(prevDailyBudget)) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            campaignDailyBudgetConfirmDialog(onSubmit)
          )
        )
      } else {
        onSubmit()
      }
    }

    if (
      dailyBudget > 0 &&
      dailyBudget < (prevDailyBudget || Number.POSITIVE_INFINITY)
    ) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          campaignDailyBudgetModifyWarningDialog({ onOK })
        )
      )
    } else {
      onOK()
    }
  }
}
