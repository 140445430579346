import React from 'react'
import { showErrorMessage } from '../../../utils/alertUtils'
import { openPopupByProxy, POPUP_KEY } from '../../common/mPopup'
import { toInvalidApproachPath } from '../../../utils/router/routeUtils'
import PopupProxy from '../../../components/Popup/Common/PopupProxy'
import { NEW_CAMPAIGN_FORM_VALIDATION_KEY } from '../../../components/AdvertiseV2/CampaignAndGroupV2/Validators/newCampaignFormValidator'
import { setIsValidCampaignByKey } from '../mCampaignV2'
import { PIXEL_AND_SDK_LINKAGE_STATUS } from '../../../components/Linkage/PixelAndSdk/Utils/pixelAndSdk'
import GoalEnum from '../../../enums/GoalEnum'
import {
  pixelAndSdkCancelLinkageCampaignConversion,
  pixelAndSdkCancelLinkageCampaignVisiting,
  pixelAndSdkDeleteLinkageCampaignConversion,
  pixelAndSdkDeleteLinkageCampaignVisiting,
} from '../../../components/Linkage/PixelAndSdk/Dialog/PixelAndSdkDialog'
import CampaignTypeEnum from '../../../enums/CampaignTypeEnum'
import {
  notValidContractDialog,
  campaignUnavailableCampaignTypeGoalDialog,
  campaignPersonalMessageConversionChangedDialog,
  alreadyUsedContractDialog,
} from '../../../components/DashboardV3/Dialog/CampaignDialog'
import { RouterV2 } from '../../../stores/middleware/routerMiddleware'

/**
 * 이전 일 예산 보다 작게 설정될 경우 warning
 * 미설정: Infinity
 */
export function checkCampaignDailyBudgetChanging(
  dailyBudget,
  prevDailyBudget = null,
  onAccept = () => {}
) {
  return dispatch => {
    const warning =
      dailyBudget !== null &&
      dailyBudget < (prevDailyBudget || Number.POSITIVE_INFINITY)

    if (warning) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy hasSecondaryButton={true} primaryButtonFunc={onAccept}>
            <strong className="tit_layer">일 예산 수정에 따른 재설정</strong>
            <p className="txt_layer">
              캠페인 일 예산보다 큰 광고그룹 일 예산 및<br />
              수정되는 일 예산의 50%를 넘는 광고그룹 입찰금액이
              <br />
              입력값 기준으로 수정됩니다.
              <br />
              계속하시겠습니까?
            </p>
          </PopupProxy>
        )
      )
    } else {
      onAccept()
    }
  }
}

/**
 * 픽셀 선택된 경우 캠페인 수정 진입시, 픽셀의 상태 체크하여 연결해제 or 삭제된 경우 warning
 * @param campaignType
 * @param goal
 * @param status
 * @param isCatalogDeleted
 */
export function checkCampaignModifyTrackStatusAlert(
  campaignType,
  goal,
  status,
  isCatalogDeleted
) {
  return dispatch => {
    const isConversionAlertType = goal === GoalEnum.Type.CONVERSION
    const isVisitingAlertType =
      goal === GoalEnum.Type.VISITING ||
      campaignType === CampaignTypeEnum.Type.VIDEO ||
      campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING ||
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED

    const checkCatalogAlert = () => {
      if (isCatalogDeleted) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">카탈로그 삭제됨</strong>
              <p className="txt_layer">
                해당 캠페인에 설정된 카탈로그가 삭제되어
                <br />
                더이상 정상적인 광고 집행 및 전환 추적이 불가합니다.
                <br />
                사용 가능한 카탈로그로 캠페인을 새로 등록하세요.
              </p>
            </PopupProxy>
          )
        )
      }
    }

    if (status === PIXEL_AND_SDK_LINKAGE_STATUS.DELETED) {
      if (isConversionAlertType) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            pixelAndSdkDeleteLinkageCampaignConversion()
          )
        )
      } else if (isVisitingAlertType) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            pixelAndSdkDeleteLinkageCampaignVisiting()
          )
        )
      }
    } else if (status === PIXEL_AND_SDK_LINKAGE_STATUS.UNLINKED) {
      if (isConversionAlertType) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            pixelAndSdkCancelLinkageCampaignConversion(checkCatalogAlert)
          )
        )
      } else if (isVisitingAlertType) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            pixelAndSdkCancelLinkageCampaignVisiting()
          )
        )
      }
    } else {
      checkCatalogAlert()
    }
  }
}

export function campaignPersonalMessageConversionChanged(
  isTrackIdChanged,
  onAccept
) {
  return dispatch => {
    if (isTrackIdChanged) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          campaignPersonalMessageConversionChangedDialog(onAccept)
        )
      )
    }
  }
}

export function handleCampaignFormExceptionV2(dispatch, e) {
  if (!e || !e.response || !e.response.data) return

  const { errorCode, message } = e.response.data

  switch (errorCode) {
    // 캠페인 이름 중복.
    case 21007: {
      dispatch(
        setIsValidCampaignByKey(
          NEW_CAMPAIGN_FORM_VALIDATION_KEY.CAMPAIGN_NAME,
          false,
          '동일 광고계정에서 이미 사용 중인 캠페인 이름입니다.'
        )
      )
      break
    }

    // 캠페인이 존재하지 않음.
    case 31001: {
      dispatch(RouterV2.replace(toInvalidApproachPath()))
      break
    }

    // limit over
    case 21011: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">캠페인 제한 수 초과</strong>
            <p className="txt_layer">
              광고계정 내 캠페인 개수 제한으로
              <br />
              캠페인을 저장할 수 없습니다.
              <br />
              기존 캠페인을 삭제한 후 다시 시도하세요.
            </p>
          </PopupProxy>
        )
      )
      break
    }

    // 쇼케이스 에러
    case 31028: {
      showErrorMessage('카탈로그가 유효하지 않습니다.')
      break
    }

    // 비즈보드 CPT 사용불가 유형
    case 35002: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          campaignUnavailableCampaignTypeGoalDialog()
        )
      )
      break
    }

    case 39805:
    case 39806: {
      dispatch(
        openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, notValidContractDialog())
      )
      break
    }

    case 39808: {
      dispatch(
        openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, alreadyUsedContractDialog())
      )
      break
    }

    // 카카오톡 채널 연결 실패.
    case 84001: {
      showErrorMessage('카카오톡 채널 연결에 실패하였습니다. 다시 시도하세요.')
      break
    }

    case 84007: {
      showErrorMessage(
        '선택한 카카오톡 채널의 상태가 휴면, 제재, 삭제 등의 사유로 정상 상태가 아니거나, 해당 채널에 권한이 없어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 채널의 상태 및 권한을 확인하세요.'
      )
      break
    }

    // 픽셀관련
    case 89708: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">픽셀 & SDK 권한 오류</strong>
            <p className="txt_layer">
              선택한 픽셀 & SDK의 권한이 없습니다.
              <br />
              처음부터 다시 진행해주시기 바랍니다.
            </p>
          </PopupProxy>
        )
      )
      break
    }

    // 카탈로그 오류
    case 51620: {
      showErrorMessage(
        '카탈로그에 문제가 있어 오류가 발생했습니다.\n카탈로그 관리에서 내 카탈로그 정보를 확인하세요.'
      )
      break
    }

    // 카탈로그 권한 오류
    case 51621: {
      showErrorMessage(
        '해당 카탈로그의 권한이 없습니다.\n 카탈로그 관리에서 내 카탈로그 정보를 확인하세요.'
      )
      break
    }

    case 34533: // 카카오톡 채널에 고객센터 연락처 누락
    case 34534: // 카카오톡 채널의 상태가 변경 (휴면 제재 등)
    case 34535: {
      // 카카오톡 채널의 홈노출 off
      showErrorMessage(message)
      break
    }

    default: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">시스템 오류</strong>
            <p className="txt_layer">
              시스템 오류가 발생하였습니다.
              <br />
              잠시 후 다시 시도하세요.
              <br />
              시스템 오류가 반복될 경우 처음부터 다시 진행하세요.
              <br />
              (errorCode: {errorCode})
            </p>
          </PopupProxy>
        )
      )
      break
    }
  }
}
