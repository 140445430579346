import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import GoalEnum from '../../enums/GoalEnum'
import PricingTypeEnum from '../../enums/PricingTypeEnum'

const CampaignTypeGoalConstraints = {
  noLongerSupported({ campaignType, goal, pricingType }) {
    const a = CampaignTypeEnum.isDeprecated(campaignType)
    const b =
      campaignType === CampaignTypeEnum.Type.VIDEO &&
      goal === GoalEnum.Type.VIEW &&
      pricingType === PricingTypeEnum.Type.CPM

    return a || b
  },

  isManualBidSupported({ campaignType, goal }) {
    return !(
      (campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING &&
        goal === GoalEnum.Type.REACH) ||
      CampaignTypeEnum.isContractCampaignType(campaignType) ||
      (campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
        goal === GoalEnum.Type.REACH) ||
      (campaignType === CampaignTypeEnum.Type.ELECTION_2024_04 &&
        goal === GoalEnum.Type.REACH) ||
      (CampaignTypeEnum.isElectionCampaignType(campaignType) &&
        goal === GoalEnum.Type.REACH)
    )
  },

  isRelatedToBid({ campaignType, goal }) {
    return (
      [
        GoalEnum.Type.CONVERSION,
        GoalEnum.Type.VISITING,
        GoalEnum.Type.VIEW,
      ].includes(goal) ||
      (campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD &&
        goal === GoalEnum.Type.REACH) ||
      CampaignTypeEnum.isElectionCampaignType(campaignType)
    )
  },

  isRelatedToPurchase({ campaignType, goal }) {
    return (
      [
        CampaignTypeEnum.Type.TALK_CHANNEL,
        CampaignTypeEnum.Type.KAKAO_TV,
        CampaignTypeEnum.Type.ELECTION_2022_03,
        CampaignTypeEnum.Type.ELECTION_2022_06,
        CampaignTypeEnum.Type.DAUM_SHOPPING,
        CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED,
        CampaignTypeEnum.Type.PERSONAL_MESSAGE,
        CampaignTypeEnum.Type.ELECTION_2024_04,
        CampaignTypeEnum.Type.PC_TALK_BOTTOM,
        CampaignTypeEnum.Type.PC_TALK_RICH_POP,
        CampaignTypeEnum.Type.FOCUS_FULL_VIEW,
      ].includes(campaignType) && goal === GoalEnum.Type.REACH
    )
  },
}

export default CampaignTypeGoalConstraints
