import React from 'react'
import { POPUP_KEY } from '../../modules/common/mPopup'

/**
 * 주요 컴포넌트는 lazy 처리하지 않는다.
 * - BizBoardBanner lazy 시 이슈 있음(CreativeForm remount).
 */
import SimplePopup from './Common/SimplePopup/SimplePopup'
import BasicPopup from './Common/BasicPopup/BasicPopup'
import DashboardPromiseMessageDetail from './PromiseMessageDetail/DashboardPromiseMessageDetail'
import TargetPromiseMessageDetail from './PromiseMessageDetail/TargetPromiseMessageDetail'
import CreativeLibrary from '../AdvertiseV2/Creative/CreativeLibrary/Image/CreativeLibrary'
import CreativeLibraryImageEditor from '../AdvertiseV2/Creative/CreativeLibrary/ImageEditor/CreativeLibraryImageEditor'
import BizBoardBanner from '../AdvertiseV2/Creative/BizBoardBanner/BizBoardBanner'
import CmpAssetLibraryImage from '../AdvertiseV2/Creative/Cmp/CmpAssetLibrary/Image/CmpAssetLibraryImage'
import CmpAssetLibraryVideo from '../AdvertiseV2/Creative/Cmp/CmpAssetLibrary/Video/CmpAssetLibraryVideo'
import ReportSharePopup from '../ReportV2/Popup/ReportShare/ReportSharePopup'
import ReportUpdateNamePopUp from '../ReportV2/Popup/ReportUpdateName/ReportUpdateNamePopUp'

const FullScreenAdViewPreview = React.lazy(() =>
  import('./FullScreenPreview/FullScreenAdViewPreview')
)
const FullScreenMessagePreview = React.lazy(() =>
  import('./FullScreenPreview/FullScreenMessagePreview')
)
const MultipleImageUploadFailureSummary = React.lazy(() =>
  import(
    '../AdvertiseV2/Creative/Form/Viewable/MultipleImageUploadFailureSummary'
  )
)
const CreativePreviewBannerCreateDetail = React.lazy(() =>
  import('../AdvertiseV2/Creative/Preview/CreativePreviewBannerCreateDetail')
)
const CreativeReviewDenyReasons = React.lazy(() =>
  import('./Dashboard/CreativeReviewDenyReasons/CreativeReviewDenyReasons')
)
const AdGroupTargetSummary = React.lazy(() =>
  import('./Dashboard/AdGroupTargetSummary/AdGroupTargetSummary')
)
const KakaoContentsTargetSummary = React.lazy(() =>
  import('./Dashboard/AdGroupTargetSummary/KakaoContentsTargetSummary')
)
const CopyAdGroup = React.lazy(() => import('../DashboardV3/Popup/CopyAdGroup'))
const CopyCreative = React.lazy(() =>
  import('../DashboardV3/Popup/CopyCreative')
)

const AdminStopHistory = React.lazy(() =>
  import('../DashboardV3/Popup/AdminStopHistory')
)

const AdGroupDynamicCatalogBasicInfoSummary = React.lazy(() =>
  import(
    './Dashboard/AdGroupDynamicCatalogBasicInfoSummary/AdGroupDynamicCatalogBasicInfoSummary'
  )
)

const CancelCptContract = React.lazy(() =>
  import('../DashboardV3/Popup/CancelCptContract')
)

const CreativePreview = React.lazy(() =>
  import('./Dashboard/CreativePreview/CreativePreview')
)
const CreativePreviewModifyingMessage = React.lazy(() =>
  import('./Dashboard/CreativePreview/CreativePreviewModifyingMessage')
)
const CreativePreviewModifyingHistory = React.lazy(() =>
  import('./Dashboard/CreativePreview/CreativePreviewModifyingHistory')
)
const PaymentCardAdd = React.lazy(() =>
  import('../Settings/PaymentCardManagement/Popup/PaymentCardAdd')
)
const EditLogDetail = React.lazy(() => import('./Settings/EditLogDetail'))
const InviteMemberV2 = React.lazy(() =>
  import(
    '../../components/Settings/AccountManagement/AccountMemberManagementV2/Popup/InviteMemberV2'
  )
)
const ChangeMasterV2 = React.lazy(() =>
  import(
    '../../components/Settings/AccountManagement/AccountMemberManagementV2/Popup/ChangeMasterV2'
  )
)
const ReviewDocumentCreate = React.lazy(() =>
  import('./Settings/ReviewManagement')
)
const CashCharge = React.lazy(() =>
  import('./Settings/CashManagement/CashCharge')
)
const RefundGuide = React.lazy(() =>
  import('./Settings/CashManagement/RefundGuide')
)
const RequestRefund = React.lazy(() =>
  import('./Settings/CashManagement/RequestRefund')
)
const RefundHistory = React.lazy(() =>
  import('./Settings/CashManagement/RefundHistory')
)
const ModifyRefundAccount = React.lazy(() =>
  import('./Settings/CashManagement/ModifyRefundAccount')
)
const RequestPayment = React.lazy(() =>
  import('./Settings/CashManagement/RequestPayment')
)
const PaymentDetail = React.lazy(() =>
  import('./Settings/CashManagement/PaymentDetail')
)
const CancellationFeeDetail = React.lazy(() =>
  import('./Settings/CashManagement/Popup/CancellationFeeDetail')
)
const VirtualAccountHistory = React.lazy(() =>
  import('./CashManagement/VirtualAccountHistory')
)
const DeferredPaymentLimitChangeLog = React.lazy(() =>
  import('./Settings/DeferredPaymentLimitChangeLog')
)
const EditAdAccountNameV2 = React.lazy(() =>
  import('./Settings/AccountManagement/EditAdAccountName/EditAdAccountNameV2')
)
const DeleteAdAccountV2 = React.lazy(() =>
  import('./Settings/AccountManagement/DeleteAdAccount/DeleteAdAccountV2')
)
const CancelDeleteAdAccountV2 = React.lazy(() =>
  import(
    './Settings/AccountManagement/CancelDeleteAdAccount/CancelDeleteAdAccountV2'
  )
)
const WithdrawMember = React.lazy(() =>
  import('./WithdrawMember/WithdrawMember')
)
const PlusFriendGroupFile = React.lazy(() =>
  import('./Targeting/PlusFriendGroupFile')
)
const CustomerFilesUploadV2 = React.lazy(() =>
  import('../Targeting/CustomerFiles/Popup')
)
const CohortDataGuide = React.lazy(() =>
  import('../Targeting/Cohort/CohortDataGuide')
)
const PixelAndSdkCreate = React.lazy(() =>
  import('../Linkage/PixelAndSdk/Popup/Create/PixelAndSdkCreate')
)
const PixelKakaoServiceLinkage = React.lazy(() =>
  import(
    '../Linkage/PixelAndSdk/Popup/Create/ServiceLink/PixelKakaoServiceLinkage'
  )
)
const PixelAndSdkInstall = React.lazy(() =>
  import(
    '../Linkage/PixelAndSdk/Popup/Create/PixelAndSdkInstall/PixelAndSdkInstall'
  )
)
const PixelAndSdkMemberRequest = React.lazy(() =>
  import('../Linkage/PixelAndSdk/Popup/MemberRequest/PixelAndSdkMemberRequest')
)
const LoadCustomerFile = React.lazy(() =>
  import('../AdvertiseV2/Popup/LoadCustomerFile/LoadCustomerFile')
)
const LoadCohortV2 = React.lazy(() =>
  import('../AdvertiseV2/Popup/LoadCohort/LoadCohort')
)
const LoadPixelAndSDKV2 = React.lazy(() =>
  import('../AdvertiseV2/Popup/LoadPixelAndSDK/LoadPixelAndSDK')
)
const LoadKakaoUsersV2 = React.lazy(() =>
  import('../AdvertiseV2/Popup/LoadKakaoUsers/LoadKakaoUsers')
)
const LoadFriendGroupV2 = React.lazy(() =>
  import('../AdvertiseV2/Popup/LoadPlusFriendGroup/LoadPlusFriendGroup')
)
const LoadCategoryTargeting = React.lazy(() =>
  import('../AdvertiseV2/Popup/LoadCategoryTarget/LoadCategoryTargeting')
)
const LoadKakaoTvTarget = React.lazy(() =>
  import('../AdvertiseV2/Popup/LoadKakaoTvTarget')
)
const DspAutoPaymentCardServiceAgreement = React.lazy(() =>
  import('../My/MyInfo/DspAutoPaymentCard/DspAutoPaymentCardServiceAgreement')
)
const LoadAdView = React.lazy(() =>
  import('../AdViewV2/Popup/LoadAdView/LoadAdView')
)
const LoadAdministrativeDistrict = React.lazy(() =>
  import(
    '../AdvertiseV2/Popup/LoadAdministrativeDistrict/LoadAdministrativeDistrict'
  )
)
const SelectAdGroup = React.lazy(() =>
  import('../AdvertiseV2/Popup/SelectAdGroup/SelectAdGroup')
)
const AddBizFormAuthority = React.lazy(() =>
  import('./Linkage/BizForm/AddBizFormAuthority/AddBizFormAuthority')
)
const RobotAnalysis = React.lazy(() => import('./RobotReports/RobotReports'))

const SendTestMessage = React.lazy(() =>
  import('./Message/SendTestMessage/SendTestMessage')
)
const LoadAudience = React.lazy(() =>
  import('../AdvertiseV2/Popup/LoadAudience/LoadAudience')
)
const SaveAudience = React.lazy(() =>
  import('../AdvertiseV2/Popup/SaveAudience/SaveAudience')
)
const CreateAdGroupCreative = React.lazy(() =>
  import('../DashboardV3/DashboardTable/CreateAdGroupCreative')
)
const DashboardFilterStore = React.lazy(() =>
  import('../DashboardV3/DashboardHeader/DashboardFilter/DashboardFilterStore')
)
const DashboardTableMetricSetting = React.lazy(() =>
  import('../DashboardV3/DashboardTable/Common/Metric/TableMetricSetting')
)
const AdGroupTableBidAmountModifyBulk = React.lazy(() =>
  import(
    '../DashboardV3/DashboardTable/AdGroup/AdGroupTableBidAmountModifyBulk'
  )
)
const AddTalkChannelCsInfo = React.lazy(() =>
  import('../AdvertiseV2/Popup/AddTalkChannelCsInfo/AddTalkChannelCsInfo')
)
const CreativePreviewExpandableVideo = React.lazy(() =>
  import(
    '../AdvertiseV2/Creative/Preview/Expandable/CreativePreviewExpandableVideo'
  )
)
const CreativePreviewExpandableImage = React.lazy(() =>
  import(
    '../AdvertiseV2/Creative/Preview/Expandable/CreativePreviewExpandableImage'
  )
)
const CreativePreviewExpandableMulti = React.lazy(() =>
  import(
    '../AdvertiseV2/Creative/Preview/Expandable/CreativePreviewExpandableMulti'
  )
)
const CreativePreviewBubbleTab = React.lazy(() =>
  import('../AdvertiseV2/Creative/Preview/Expandable/CreativePreviewBubbleTab')
)
const CreativePreviewMessageVideo = React.lazy(() =>
  import('../AdvertiseV2/Creative/Preview/CreativePreviewMessageVideo')
)
const InappropriatePhrases = React.lazy(() =>
  import('./Message/InappropriatePhrases/InappropriatePhrases')
)

const MessageCoupon = React.lazy(() =>
  import('./Message/MessageCoupon/MessageCoupon')
)

const CmpEditorContainer = React.lazy(() =>
  import('../AdvertiseV2/Creative/Cmp/CmpEditor/CmpEditorContainer')
)

const CmpEditorLibraryForQA = React.lazy(() =>
  import('../AdvertiseV2/Creative/Cmp/CmpEditor/QA/CmpEditorLibraryForQA')
)

const MessageDetailPreview = React.lazy(() =>
  import('../Message/MessageDetail/MessageDetailPreview/MessageDetailPreviewV2')
)

const DimensionMetricSetting = React.lazy(() =>
  import('../ReportV2/Popup/DimensionMetricSetting/DimensionMetricSetting')
)

const ReportSave = React.lazy(() =>
  import('../ReportV2/Popup/ReportSave/ReportSave')
)

const ReportDownload = React.lazy(() =>
  import('../ReportV2/Popup/ReportDownload/ReportDownload')
)

const PersonalMessageVariables = React.lazy(() =>
  import('./PersonalMessage/Variables/PersonalMessageVariables')
)
const TrackingUrlAddPopUp = React.lazy(() =>
  import(
    '../AdvertiseV2/Creative/PopUp/UrlParameterAddPopUp/CatalogTrackingUrlParameterAddPopup'
  )
)

const UrlParameterAddPopup = React.lazy(() =>
  import(
    '../AdvertiseV2/Creative/PopUp/UrlParameterAddPopUp/UrlParameterAddPopup'
  )
)

const BookingCalendar = React.lazy(() =>
  import('../AdvertiseV2/CampaignAndGroupV2/Popup/BookingCalendar')
)

const PriceTable = React.lazy(() =>
  import('../AdvertiseV2/CampaignAndGroupV2/Popup/PrieceTablePopup')
)

const PriceTableForElection2024 = React.lazy(() =>
  import(
    '../AdvertiseV2/CampaignAndGroupV2/Popup/PriceTableForElection2024Popup'
  )
)

const AdViewDetailPreview = React.lazy(() =>
  import('../AdViewV2/AdViewDetail/AdViewDetailPreview/AdViewDetailPreview')
)

const KclidCheckList = React.lazy(() =>
  import('../AdvertiseV2/CampaignAndGroupV2/Campaign/Objective/KclidCheckList')
)

export const PopupComponentMap = {
  [POPUP_KEY.SIMPLE_POPUP]: <SimplePopup />,
  [POPUP_KEY.BASIC_POPUP]: <BasicPopup />,
  [POPUP_KEY.FULL_SCREEN_AD_VIEW]: <FullScreenAdViewPreview />,
  [POPUP_KEY.FULL_SCREEN_MESSAGE]: <FullScreenMessagePreview />,
  [POPUP_KEY.MULTI_IMAGE_UPLOAD_FAILURE_SUMMARY]: (
    <MultipleImageUploadFailureSummary />
  ),
  [POPUP_KEY.CREATIVE_LIBRARY]: <CreativeLibrary />,
  [POPUP_KEY.CREATIVE_LIBRARY_IMAGE_EDITOR]: <CreativeLibraryImageEditor />,
  [POPUP_KEY.BIZ_BOARD_BANNER]: <BizBoardBanner />,
  [POPUP_KEY.CREATIVE_PREVIEW_BANNER_DETAIL]: (
    <CreativePreviewBannerCreateDetail />
  ),
  [POPUP_KEY.CREATIVE_REVIEW_DENY_REASONS]: <CreativeReviewDenyReasons />,
  [POPUP_KEY.AD_TARGET_SUMMARY]: <AdGroupTargetSummary />,
  [POPUP_KEY.KAKAO_CONTENTS_TARGET_SUMMARY]: <KakaoContentsTargetSummary />,
  [POPUP_KEY.COPY_AD_GROUP]: <CopyAdGroup />,
  [POPUP_KEY.COPY_CREATIVE]: <CopyCreative />,
  [POPUP_KEY.ADMIN_STOP_HISTORY]: <AdminStopHistory />,
  [POPUP_KEY.AD_GROUP_BASIC_INFO_CATALOG_SUMMARY]: (
    <AdGroupDynamicCatalogBasicInfoSummary />
  ),
  [POPUP_KEY.CANCEL_CPT_CONTRACT]: <CancelCptContract />,
  [POPUP_KEY.CREATIVE_PREVIEW]: <CreativePreview />,
  [POPUP_KEY.CREATIVE_PREVIEW_MODIFYING_MESSAGE]: (
    <CreativePreviewModifyingMessage />
  ),
  [POPUP_KEY.CREATIVE_PREVIEW_MODIFYING_HISTORY]: (
    <CreativePreviewModifyingHistory />
  ),
  [POPUP_KEY.PAYMENT_CARD_ADD]: <PaymentCardAdd />,
  [POPUP_KEY.EDIT_LOG_DETAIL]: <EditLogDetail />,
  [POPUP_KEY.INVITE_MEMBER]: <InviteMemberV2 />,
  [POPUP_KEY.CHANGE_MASTER]: <ChangeMasterV2 />,
  [POPUP_KEY.REVIEW_DOCUMENT_CREATE]: <ReviewDocumentCreate />,
  [POPUP_KEY.CASH_CHARGE]: <CashCharge />,
  [POPUP_KEY.REFUND_GUIDE]: <RefundGuide />,
  [POPUP_KEY.REQUEST_REFUND]: <RequestRefund />,
  [POPUP_KEY.REFUND_HISTORY]: <RefundHistory />,
  [POPUP_KEY.MODIFY_REFUND_ACCOUNT]: <ModifyRefundAccount />,
  [POPUP_KEY.REQUEST_PAYMENT]: <RequestPayment />,
  [POPUP_KEY.PAYMENT_DETAIL]: <PaymentDetail />,
  [POPUP_KEY.CANCELLATION_FEE_DETAIL]: <CancellationFeeDetail />,
  [POPUP_KEY.VIRTUAL_ACCOUNT_HISTORY]: <VirtualAccountHistory />,
  [POPUP_KEY.DEFERRED_PAYMENT_CHANGE_LOG]: <DeferredPaymentLimitChangeLog />,
  [POPUP_KEY.EDIT_AD_ACCOUNT_NAME]: <EditAdAccountNameV2 />,
  [POPUP_KEY.DELETE_AD_ACCOUNT]: <DeleteAdAccountV2 />,
  [POPUP_KEY.CANCEL_DELETE_AD_ACCOUNT]: <CancelDeleteAdAccountV2 />,
  [POPUP_KEY.WITHDRAW_MEMBER]: <WithdrawMember />,
  [POPUP_KEY.PLUS_FRIEND_GROUP_FILE]: <PlusFriendGroupFile />,
  [POPUP_KEY.CUSTOMER_FILES_UPLOAD]: <CustomerFilesUploadV2 />,
  [POPUP_KEY.COHORT_DATA_GUIDE]: <CohortDataGuide />,
  [POPUP_KEY.CREATE_PIXEL_AND_SDK_V2]: <PixelAndSdkCreate />,
  [POPUP_KEY.PIXEL_KAKAO_SERVICE_LINKAGE]: <PixelKakaoServiceLinkage />,
  [POPUP_KEY.PIXEL_AND_SDK_INSTALL]: <PixelAndSdkInstall />,
  [POPUP_KEY.ADD_MEMBER_REQUEST_RIGHTS]: <PixelAndSdkMemberRequest />,
  [POPUP_KEY.LOAD_CUSTOMER_FILE_V2]: <LoadCustomerFile />,
  [POPUP_KEY.LOAD_COHORT_V2]: <LoadCohortV2 />,
  [POPUP_KEY.LOAD_PIXEL_AND_SDK_V2]: <LoadPixelAndSDKV2 />,
  [POPUP_KEY.LOAD_KAKAO_USERS_V2]: <LoadKakaoUsersV2 />,
  [POPUP_KEY.LOAD_FRIEND_GROUP_V2]: <LoadFriendGroupV2 />,
  [POPUP_KEY.LOAD_CATEGORY_TARGET_V2]: <LoadCategoryTargeting />,
  [POPUP_KEY.LOAD_ADMINISTRATIVE_DISTRICT]: <LoadAdministrativeDistrict />,
  [POPUP_KEY.LOAD_KAKAO_TV_TARGET]: <LoadKakaoTvTarget />,
  [POPUP_KEY.DSP_AUTO_PAYMENT_CARD_SERVICE_AGREEMENT]: (
    <DspAutoPaymentCardServiceAgreement />
  ),
  [POPUP_KEY.LOAD_AD_VIEW]: <LoadAdView />,
  [POPUP_KEY.SELECT_AD_GROUP]: <SelectAdGroup />,
  [POPUP_KEY.ADD_BIZ_FORM_AUTHORITY]: <AddBizFormAuthority />,
  [POPUP_KEY.ROBOT_ANALYSIS]: <RobotAnalysis />,
  [POPUP_KEY.SEND_TEST_MESSAGE]: <SendTestMessage />,
  [POPUP_KEY.LOAD_AUDIENCE]: <LoadAudience />,
  [POPUP_KEY.SAVE_AUDIENCE]: <SaveAudience />,
  [POPUP_KEY.DASHBOARD_CREATE_AD_GROUP_CREATIVE]: <CreateAdGroupCreative />,
  [POPUP_KEY.DASHBOARD_FILTER_STORE]: <DashboardFilterStore />,
  [POPUP_KEY.DASHBOARD_TABLE_METRIC_SETTING]: <DashboardTableMetricSetting />,
  [POPUP_KEY.DASHBOARD_AD_GROUP_BID_AMOUNT_MODIFY_BULK]: (
    <AdGroupTableBidAmountModifyBulk />
  ),
  [POPUP_KEY.DASHBOARD_PROMISE_MESSAGE_DETAIL]: (
    <DashboardPromiseMessageDetail />
  ),
  [POPUP_KEY.TARGET_PROMISE_MESSAGE_DETAIL]: <TargetPromiseMessageDetail />,
  [POPUP_KEY.ADD_TALK_CHANNEL_CS_INFO]: <AddTalkChannelCsInfo />,
  [POPUP_KEY.CREATIVE_PREVIEW_EXPANDABLE_VIDEO]: (
    <CreativePreviewExpandableVideo />
  ),
  [POPUP_KEY.CREATIVE_PREVIEW_EXPANDABLE_IMAGE]: (
    <CreativePreviewExpandableImage />
  ),
  [POPUP_KEY.CREATIVE_PREVIEW_EXPANDABLE_MULTI]: (
    <CreativePreviewExpandableMulti />
  ),
  [POPUP_KEY.CREATIVE_PREVIEW_BUBBLETAB]: <CreativePreviewBubbleTab />,
  [POPUP_KEY.CREATIVE_PREVIEW_MESSAGE_VIDEO]: <CreativePreviewMessageVideo />,
  [POPUP_KEY.INAPPROPRIATE_PHRASES]: <InappropriatePhrases />,
  [POPUP_KEY.MESSAGE_COUPON]: <MessageCoupon />,
  [POPUP_KEY.CMP_ASSET_LIBRARY_IMAGE]: <CmpAssetLibraryImage />,
  [POPUP_KEY.CMP_ASSET_LIBRARY_VIDEO]: <CmpAssetLibraryVideo />,
  [POPUP_KEY.CMP_EDITOR]: <CmpEditorContainer />,
  [POPUP_KEY.CMP_EDITOR_LIBRARY_FOR_QA]: <CmpEditorLibraryForQA />,
  [POPUP_KEY.MESSAGE_DETAIL_PREVIEW]: <MessageDetailPreview />,
  [POPUP_KEY.REPORT_SHARE]: <ReportSharePopup />,
  [POPUP_KEY.REPORT_UPDATE_NAME]: <ReportUpdateNamePopUp />,
  [POPUP_KEY.REPORT_DIMENSION_METRIC_SETTING]: <DimensionMetricSetting />,
  [POPUP_KEY.REPORT_SAVE]: <ReportSave />,
  [POPUP_KEY.REPORT_DOWNLOAD]: <ReportDownload />,
  [POPUP_KEY.PERSONAL_MESSAGE_VARIABLES]: <PersonalMessageVariables />,
  [POPUP_KEY.TRACKING_URL_ADD_POPUP]: <TrackingUrlAddPopUp />,
  [POPUP_KEY.URL_PARAMETER_ADD_POPUP]: <UrlParameterAddPopup />,
  [POPUP_KEY.BOOKING_CALENDAR]: <BookingCalendar />,
  [POPUP_KEY.PRICE_TABLE]: <PriceTable />,
  [POPUP_KEY.PRICE_TABLE_ELECTION_2024]: <PriceTableForElection2024 />,
  [POPUP_KEY.ADVIEW_DETAIL_PREVIEW]: <AdViewDetailPreview />,
  [POPUP_KEY.KCLID_CHECK_LIST]: <KclidCheckList />,
}
