import PopupProxy from '../../Popup/Common/PopupProxy'
import React from 'react'
import { NumberUtils } from '../../../utils/numberUtils'
import AdStatusEnum from '../../../enums/AdStatusEnum'

const adGroupResumeWarningForTalkChannelReachDialog = ({ onOK, onCancel }) => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      primaryButtonFunc={onOK}
      onDismiss={onCancel}>
      <strong className="tit_layer">광고 재개</strong>
      <p className="txt_layer">광고 집행을 재개 하시겠습니까?</p>
      <div className="adbox_scroll">
        <div className="inner_adbox_scroll">
          <strong className="tit_adbox">[카카오톡 채널 유형]</strong>
          <p className="txt_adbox">재개 시 메시지 발송이 가능 합니다.</p>
        </div>
      </div>
    </PopupProxy>
  )
}

const adGroupPauseWarningDialog = ({
  onOK,
  onCancel,
  hasTalkChannelReach,
  hasDaumShoppingReach,
  hasTalkBizBoardReserved,
  hasPersonalMessageReach,
  hasElection2024,
  hasPcTalkBottom,
  hasPcTalkRichPop,
  hasFocusFullView,
}) => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      primaryButtonFunc={onOK}
      onDismiss={onCancel}>
      <strong className="tit_layer">광고 일시 중지</strong>
      <p className="txt_layer">광고 집행을 일시 중지하시겠습니까?</p>
      <div className="adbox_scroll">
        <div className="inner_adbox_scroll">
          {hasTalkChannelReach && (
            <>
              <strong className="tit_adbox">[카카오톡 채널 유형]</strong>
              <p className="txt_adbox">
                중지 후 집행 기간 내 발송 재개 가능합니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasDaumShoppingReach && (
            <>
              <strong className="tit_adbox">[다음쇼핑 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 일 단위로 계속 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasTalkBizBoardReserved && (
            <>
              <strong className="tit_adbox">[카카오 비즈보드 CPT 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasPersonalMessageReach && (
            <>
              <strong className="tit_adbox">[개인화 메시지 유형]</strong>
              <p className="txt_adbox">
                발송 중이거나 발송 예정인 메시지가 있는 경우 발송이 완전히
                취소됩니다. (발송 재개 불가)
              </p>
            </>
          )}
          {hasElection2024 && (
            <>
              <strong className="tit_adbox">[제22대 국회의원 선거 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasPcTalkBottom && (
            <>
              <strong className="tit_adbox">[포커스 보드 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasPcTalkRichPop && (
            <>
              <strong className="tit_adbox">[리치팝 올데이 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
          {hasFocusFullView && (
            <>
              <strong className="tit_adbox">[포커스 풀뷰 유형]</strong>
              <p className="txt_adbox">
                노출은 제한되나 광고비는 전액 과금됩니다. 완전히 광고 집행을
                중단하려면 계약 해지를 해야합니다.
              </p>
            </>
          )}
        </div>
      </div>
    </PopupProxy>
  )
}

const adGroupCancelContractWarningForTalkChannelDialog = ({ onOK }) => {
  return (
    <PopupProxy
      primaryButtonFunc={onOK}
      hasCloseButton={true}
      hasSecondaryButton={true}>
      <strong className="tit_layer">계약 해지</strong>
      <p className="txt_layer">
        광고 계약을 해지 하시겠습니까?
        <br />
        계약 해지 시 발송예정/발송중이던 메시지는 모두 취소되며,
        <br />
        남은 잔액은 환급됩니다.
        <br />
        (환급까지 일정시간 소요될 수 있습니다)
      </p>
    </PopupProxy>
  )
}

const adGroupCancelContractWarningForDaumShoppingDialog = ({
  expectedCancellationFee,
  onOK,
  adGroupStatus,
}) => {
  const isLive = adGroupStatus === AdStatusEnum.Type.LIVE

  return (
    <PopupProxy
      primaryButtonFunc={onOK}
      hasCloseButton={true}
      hasSecondaryButton={true}>
      <strong className="tit_layer">계약 해지</strong>
      <p className="txt_layer" style={{ whiteSpace: 'nowrap' }}>
        광고 계약을 해지 하시겠습니까?
        <br />
        {isLive ? (
          <>
            계약 해지 시 광고 노출은 즉시 중단되고
            <br />
            아래와 같이 위약금/계약 해지 수수료가 부과되며, 남은 잔액은 즉시
            환급됩니다.
          </>
        ) : (
          <>
            계약 해지 시 아래와 같이 위약금/계약 해지 수수료가 부과되며,
            <br />
            남은 잔액은 즉시 환급됩니다.
          </>
        )}
        <br />
        <em className="fw_bold fc_emph2">
          위약금/계약 해지 수수료 :{' '}
          {NumberUtils.withCommas(expectedCancellationFee)}원
        </em>
        <br />
        (잔여 기간 예산 x 10%)
      </p>
    </PopupProxy>
  )
}

const adGroupDailyBudgetModifyWarningDialog = ({ onOK }) => {
  return (
    <PopupProxy primaryButtonFunc={onOK} hasSecondaryButton={true}>
      <strong className="tit_layer">일 예산 수정에 따른 재설정</strong>
      <p className="txt_layer">
        광고그룹 일 예산의 50%를 넘는 광고그룹 입찰금액이
        <br />
        입력값 기준으로 수정됩니다.
        <br />
        계속하시겠습니까?
      </p>
    </PopupProxy>
  )
}

const adGroupDailyBudgetModifyReconfirmDialog = ({ dailyBudget, onOK }) => {
  return (
    <PopupProxy primaryButtonFunc={onOK} hasSecondaryButton={true}>
      <strong className="tit_layer">광고그룹 일 예산 수정</strong>
      <p className="txt_layer">
        일 예산이 아래와 같이 수정됩니다.
        <br />
        계속하시겠습니까?
        <br />
        <br />
        <em className="fw_bold fc_emph2">
          {NumberUtils.withCommas(dailyBudget)}원
        </em>
      </p>
    </PopupProxy>
  )
}

const adGroupBidAmountModifyReconfirmDialog = ({ bidAmount, onOK }) => {
  return (
    <PopupProxy primaryButtonFunc={onOK} hasSecondaryButton={true}>
      <strong className="tit_layer">광고그룹 입찰금액 수정</strong>
      <p className="txt_layer">
        입찰금액이 아래와 같이 수정됩니다.
        <br />
        계속하시겠습니까?
        <br />
        <br />
        <em className="fw_bold fc_emph2">
          {NumberUtils.withCommas(bidAmount)}원
        </em>
      </p>
    </PopupProxy>
  )
}

const adGroupBidAmountModifyDialog = ({ onOK }) => {
  return (
    <PopupProxy
      primaryButtonFunc={onOK}
      hasSecondaryButton={true}
      autoClose={false}>
      <strong className="tit_layer">입찰 방식 변경 안내</strong>
      <p className="txt_layer">
        기존 입찰 방식으로 설정된 입찰 금액이 모두 재설정됩니다.
        <br />
        계속하시겠습니까?
      </p>
    </PopupProxy>
  )
}

const adGroupBidStrategyTargetRoasModifyDialog = ({ value, onOK }) => {
  return (
    <PopupProxy
      primaryButtonFunc={onOK}
      hasSecondaryButton={true}
      autoClose={false}>
      <strong className="tit_layer">광고그룹 ROAS 목표 수정</strong>
      <p className="txt_layer">
        ROAS 목표가 아래와 같이 수정됩니다.
        <br />
        계속하시겠습니까?
        <br />
        <br />
        <em className="fw_bold fc_emph2">{NumberUtils.withCommas(value)}%</em>
      </p>
    </PopupProxy>
  )
}

const adGroupCountLimitCreateDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">광고그룹 제한 수 초과</strong>
      <p className="txt_layer">
        캠페인 내 광고그룹 개수 제한으로
        <br />
        광고그룹을 저장할 수 없습니다.
        <br />
        기존 광고그룹을 삭제한 후 다시 시도하세요.
      </p>
    </PopupProxy>
  )
}

const adGroupSkanOptionDialog = ({ onOk }) => {
  return (
    <PopupProxy primaryButtonFunc={onOk} hasSecondaryButton autoClose={false}>
      <strong className="tit_layer">SKAdNetwork 옵션 설정 여부 수정</strong>
      <p className="txt_layer">
        {`SKAdNetwork 옵션 설정 시 "전환수 최대화" 입찰전략만 사용 가능합니다.
        기존에 설정한 입찰 전략이 재설정됩니다.`}
        <br />
        계속하시겠습니까?
      </p>
    </PopupProxy>
  )
}

export {
  adGroupResumeWarningForTalkChannelReachDialog,
  adGroupPauseWarningDialog,
  adGroupCancelContractWarningForTalkChannelDialog,
  adGroupCancelContractWarningForDaumShoppingDialog,
  adGroupDailyBudgetModifyWarningDialog,
  adGroupDailyBudgetModifyReconfirmDialog,
  adGroupBidAmountModifyReconfirmDialog,
  adGroupBidAmountModifyDialog,
  adGroupBidStrategyTargetRoasModifyDialog,
  adGroupCountLimitCreateDialog,
  adGroupSkanOptionDialog,
}
