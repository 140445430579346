import { Map } from 'immutable'
import moment from 'moment'
import {
  checkDateFormatWithHyphen,
  checkEmpty,
  checkNotEmpty,
  hasFullWidthWhitespace,
  isPositiveIntegerSequence,
  isUndefinedOrNull,
} from '../../../../utils/regexUtils'
import { keyMirror } from '../../../../utils/utils'
import AdGroupScheduleEnum from '../../../../enums/AdGroupScheduleEnum'
import CampaignTypeEnum from '../../../../enums/CampaignTypeEnum'
import GoalEnum from '../../../../enums/GoalEnum'
import {
  getAdGroupTalkChannelPopulationMin,
  getTalkChannelSmartMessageMinCount,
  isAdGroupScheduleReady,
  memoAdGroupBidAmountConstraint,
  memoAdGroupSelectedTargetingSize,
} from '../../../../utils/advertise/campaignAdGroup'
import SchedulePeriodTypeEnum from '../../../../enums/SchedulePeriodTypeEnum'
import { getPopulationTitle } from '../CampaignAdGroupSideBar/ExpectedPopulation'
import { AdGroupHelper } from '../../../../utils/helper/helper-adGroup'
import { isKakaoOfficialChannelProfileId } from '../../../../utils/app/services/kakaoPlusFriend'
import { IS_NOT_VALID, IS_VALID } from '../../../../validators/validation'
import BidStrategyEnum from '../../../../enums/BidStrategyEnum'
import AdGroupConstraints from '../../../../utils/constraints/constraints-adGroup'
import { NumberUtils } from '../../../../utils/numberUtils'
import DemoGraphicEnum from '../../../../enums/DemoGraphicEnum'
import BidStrategyTargetEnum from '../../../../enums/BidStrategyTargetEnum'
import { isEmpty } from 'lodash'

const INVALID_MESSAGE_START_TIME_STRING = '20:55'

// 채널X도달 광고그룹 phase별 밸리데이션 기준 https://jira.daumkakao.com/browse/KAMOQA-10988
const TALK_CHANNEL_TARGETTING_MIN_CONTRACT_COUNT =
  __LOCAL__ || __DEV__ || __SANDBOX__ ? 0 : 11
const NON_TARGET_MAX_CONTRACT_COUNT = 50 * 1000 * 1000
const MAXIMUM_LOCATION_SELECT_SIZE = 1000

const TARGET_CPC_MAX_BID_AMOUNT = 10000

const TARGET_CPA_MAX_BID_AMOUNT = 1000000
const TARGET_CPA_MIN_BID_AMOUNT = 100

const TARGET_ROAS_MIN_VALUE = 10
const TARGET_ROAS_MAX_VALUE = 100000

export const NEW_AD_GROUP_FORM_TRIM_KEY_PATH_LIST = [['name']]

export const NEW_AD_GROUP_FORM_VALIDATION_KEY = keyMirror({
  SKAN_PROTOCOL_INFO: null,
  POPULATION: null,
  BID_AMOUNT: null,
  DAILY_BUDGET: null,
  LOCATION: null,
  TOTAL_BUDGET: null,
  PLACEMENTS: null,
  SCHEDULE: null,
  SCHEDULE_DATE: null,
  RETARGETING_FILTERS: null,
  AD_GROUP_NAME: null,
  BID_STRATEGY_TARGET: null,
  SUB_TYPE: null,
})

export const NEW_AD_GROUP_FORM_VALIDATION_KEY_PATH = Map({
  SKAN_PROTOCOL_INFO: ['skanProtocolInfo'],
  POPULATION: ['populationScore'],
  BID_AMOUNT: ['bidAmount'],
  DAILY_BUDGET: ['dailyBudget'],
  LOCATION: ['targeting', 'locations'],
  TOTAL_BUDGET: ['totalBudget'],
  PLACEMENTS: ['placements'],
  SCHEDULE: ['schedule'],
  SCHEDULE_DATE: ['schedule'],
  RETARGETING_FILTERS: ['retargetingFilters'],
  AD_GROUP_NAME: ['name'],
  BID_STRATEGY_TARGET: ['bidStrategyTarget'],
  SUB_TYPE: [],
})

export const validateAdGroupBidAmount = (
  bidAmount,
  dailyBudget,
  pricingType,
  bidStrategy,
  pricingTypeConstraints,
  bidStrategyTargetType = null
) => {
  const isAuto = BidStrategyEnum.isAdGroupAutoBid(bidStrategy)
  const dailyBudgetHalf = Math.floor(dailyBudget / 2)

  if (!isAuto || checkNotEmpty(bidStrategyTargetType)) {
    switch (bidStrategyTargetType) {
      case BidStrategyTargetEnum.Type.TARGET_CPC: {
        const min = TARGET_CPA_MIN_BID_AMOUNT
        const max = Math.min(
          dailyBudgetHalf <= min ? TARGET_CPC_MAX_BID_AMOUNT : dailyBudgetHalf,
          TARGET_CPC_MAX_BID_AMOUNT
        )
        if (
          !(
            isPositiveIntegerSequence(bidAmount) &&
            bidAmount >= min &&
            bidAmount <= max
          )
        ) {
          return {
            isValid: false,
            message: `${NumberUtils.toShorten(
              min
            )}원 이상 ${NumberUtils.toShorten(max)}원 이하로 입력하세요.`,
          }
        }
        break
      }
      case BidStrategyTargetEnum.Type.TARGET_CPA: {
        const min = TARGET_CPA_MIN_BID_AMOUNT
        const max = Math.min(
          dailyBudgetHalf <= min ? TARGET_CPA_MAX_BID_AMOUNT : dailyBudgetHalf,
          TARGET_CPA_MAX_BID_AMOUNT
        )

        if (
          !(
            isPositiveIntegerSequence(bidAmount) &&
            bidAmount >= min &&
            bidAmount <= max
          )
        ) {
          return {
            isValid: false,
            message: `${NumberUtils.toShorten(
              min
            )}원 이상 ${NumberUtils.toShorten(max)}원 이하로 입력하세요.`,
          }
        }
        break
      }
      case BidStrategyTargetEnum.Type.TARGET_ROAS: {
        if (
          bidAmount < TARGET_ROAS_MIN_VALUE ||
          bidAmount > TARGET_ROAS_MAX_VALUE
        ) {
          return {
            isValid: false,
            message: `${NumberUtils.withCommas(
              TARGET_ROAS_MIN_VALUE
            )}% 이상 ${NumberUtils.withCommas(
              TARGET_ROAS_MAX_VALUE
            )}% 이하로 입력하세요.`,
          }
        }
        break
      }

      default: {
        const { minBidAmount: min, maxBidAmount } =
          memoAdGroupBidAmountConstraint(pricingTypeConstraints, pricingType)

        const max = Math.min(
          dailyBudgetHalf <= min ? maxBidAmount : dailyBudgetHalf,
          maxBidAmount
        )

        if (
          !(
            isPositiveIntegerSequence(bidAmount) &&
            bidAmount >= min &&
            bidAmount <= max
          )
        ) {
          return {
            isValid: false,
            message: `${NumberUtils.toShorten(
              min
            )}원 이상 ${NumberUtils.toShorten(max)}원 이하로 입력하세요.`,
          }
        }
      }
    }
  }

  return IS_VALID()
}

const validateAdGroupName = name => {
  if (!checkNotEmpty(name)) {
    return IS_NOT_VALID('필수 입력 항목입니다.')
  }

  if (hasFullWidthWhitespace(name)) {
    return IS_NOT_VALID('전각 공백문자는 입력할 수 없습니다.')
  }

  return IS_VALID()
}

export const validateAdGroupDailyBudget = ({
  adGroupDailyBudget,
  campaignDailyBudget,
  adGroupDailyBudgetConstraint,
}) => {
  const unit = 10
  const { min, max: adGroupDailyBudgetMax } = adGroupDailyBudgetConstraint || {}
  const isCampaignUnlimited = isUndefinedOrNull(campaignDailyBudget)
  const max = isCampaignUnlimited
    ? adGroupDailyBudgetMax
    : Math.min(campaignDailyBudget, adGroupDailyBudgetMax)

  const isValid =
    isPositiveIntegerSequence(adGroupDailyBudget) &&
    adGroupDailyBudget >= min &&
    adGroupDailyBudget <= max &&
    adGroupDailyBudget % unit === 0

  if (!isValid) {
    if (isCampaignUnlimited) {
      return IS_NOT_VALID(
        `${NumberUtils.toShorten(min)}원 이상 ${NumberUtils.toShorten(
          max
        )}원 이하 ${NumberUtils.toShorten(unit)}원 단위로 입력하세요.`
      )
    } else {
      return IS_NOT_VALID(
        `${NumberUtils.toShorten(
          min
        )}원 이상 캠페인 일예산 이하 금액을 ${NumberUtils.toShorten(
          unit
        )}원 단위로 입력하세요. 원하는 일예산을 설정하려면 캠페인 일예산 검토 후 다시 시도하세요.`
      )
    }
  }

  return IS_VALID()
}

/**
 * 상세 설정이 아닌 경우 통과.
 * 종료일이 존재하며
 * 1. 종료일이 시작일보다 빠를 경우
 * 2. '종료일 없음' 체크하지 않은 상태에서 종료일 입력하지 않을 경우
 */
const validateAdGroupSchedule = (schedule, formData, getState) => {
  const {
    campaignV2: {
      campaignForm: {
        campaignTypeGoal: { campaignType, goal },
      },
    },
    adGroupV2: {
      creatingAdGroups,
      modifyAdGroup: { adGroupViewState },
      oldAdGroupForm,
    },
    contract: {
      viewState: { beginDateTime, isNewContract },
    },
  } = getState()

  const isModifyAdGroup = creatingAdGroups.isEmpty()
  const {
    key: adGroupKey,
    id,
    schedule: {
      beginDate,
      beginTime,
      endDate,
      endTime,
      detailTime,
      periodUnit,
    },
    messageSendingInfo,
  } = formData

  const { longTerm } = messageSendingInfo || {}
  const { isEndDateUnlimited } = isModifyAdGroup
    ? adGroupViewState
    : creatingAdGroups
        .find(v => v.get('key') === adGroupKey)
        .get('adGroupViewState')

  const { schedule: oldSchedule } = oldAdGroupForm

  // 카카오 TV X 도달 인경우에는 다른 유형처럼 Validation
  if (
    goal === GoalEnum.Type.REACH &&
    campaignType !== CampaignTypeEnum.Type.KAKAO_TV
  ) {
    if (campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING) {
      const isAdGroupReady = isAdGroupScheduleReady({ beginDate })
      const unitOfTime =
        periodUnit === SchedulePeriodTypeEnum.Type.MONTH ? 'M' : 'isoWeek'

      if (
        isAdGroupReady &&
        moment(beginDate)
          .startOf(unitOfTime)
          .isSameOrBefore(moment().startOf(unitOfTime))
      ) {
        return IS_NOT_VALID('현재 이후의 기간을 입력하세요.')
      }
    } else if (campaignType === CampaignTypeEnum.Type.TALK_CHANNEL) {
      const _beginDate = oldSchedule?.get('beginDate') || beginDate
      const _beginTime = oldSchedule?.get('beginTime') || beginTime
      const beginDateTime = moment(`${_beginDate} ${_beginTime}`)

      if (beginDateTime.isBefore(moment()) && id !== -1) return IS_VALID()

      if (moment(`${beginDate} ${beginTime}`).isSameOrBefore(moment())) {
        return IS_NOT_VALID('시작일시는 현재보다 미래로 설정해야 합니다.')
      }

      // 메시지 시작시간이 20시55분으로 지정되면 설정 불가 처리 (https://jira.daumkakao.com/browse/KAMOQA-20536)
      if (
        moment(`${beginDate} ${beginTime}`).isSameOrAfter(
          moment(`${beginDate} ${INVALID_MESSAGE_START_TIME_STRING}`)
        )
      ) {
        return IS_NOT_VALID('설정 불가한 시작일시입니다.')
      }

      if (longTerm) {
        if (moment(beginDate).isSame(moment(endDate))) {
          if (
            moment
              .duration(
                moment(`${endDate} ${endTime}`).diff(
                  moment(`${beginDate} ${beginTime}`)
                )
              )
              .asMinutes() <= 60
          ) {
            return IS_NOT_VALID(
              '종료일시를 시작일시보다 1시간 이후로 설정하세요.'
            )
          }
        }
      }
    } else if (CampaignTypeEnum.isContractCampaignType(campaignType)) {
      if (isNewContract && !checkNotEmpty(beginDateTime)) {
        return IS_NOT_VALID('필수 입력 항목입니다.')
      }
    } else if (CampaignTypeEnum.isElectionCampaignType(campaignType)) {
      const invalidBeginDate =
        checkEmpty(beginDate) || !checkDateFormatWithHyphen(beginDate)

      const invalidEndDate =
        checkEmpty(endDate) || moment(endDate).isBefore(moment(beginDate))

      if (invalidBeginDate || invalidEndDate) {
        return IS_NOT_VALID('시작일은 종료일보다 앞서야 합니다.')
      }
    }
  } else {
    const invalidBeginDate =
      checkEmpty(beginDate) || !checkDateFormatWithHyphen(beginDate)

    /**
     * isEndDateUnlimited 체크 시 endDate 생략 가능.
     * endDate 존재할 시 YYYY-MM-DD 포맷만 허용.
     */
    const invalidEndDate =
      (checkEmpty(endDate) && !isEndDateUnlimited) ||
      (checkNotEmpty(endDate) && !checkDateFormatWithHyphen(endDate))

    const invalidAfterCheck =
      checkNotEmpty(endDate) && !moment(endDate).isSameOrAfter(beginDate)

    if (invalidBeginDate || invalidEndDate || invalidAfterCheck) {
      return IS_NOT_VALID('시작일은 종료일보다 앞서야 합니다.')
    }

    if (detailTime) {
      const entries = AdGroupHelper.Schedule.Day.entries(schedule)
      if (
        entries.every(time =>
          time.every(v => v === AdGroupScheduleEnum.INACTIVE)
        )
      ) {
        return IS_NOT_VALID('1개 이상 설정해야 합니다.')
      }
    }
  }

  return IS_VALID()
}

export const NEW_AD_GROUP_FORM_VALIDATOR = Map({
  [NEW_AD_GROUP_FORM_VALIDATION_KEY.SKAN_PROTOCOL_INFO]: (
    skanProtocolInfo,
    formData,
    getState
  ) => {
    const {
      adGroupV2: {
        creatingAdGroups,
        modifyAdGroup: { adGroupViewState },
      },
    } = getState()

    const isModifyAdGroup = creatingAdGroups.isEmpty()
    const { key: adGroupKey } = formData
    const { isSkanNotSet } = isModifyAdGroup
      ? adGroupViewState
      : creatingAdGroups
          .find(v => v.get('key') === adGroupKey)
          .get('adGroupViewState')

    const { appId } = skanProtocolInfo || {}

    if (!isSkanNotSet && checkEmpty(appId)) {
      return IS_NOT_VALID('필수 입력 항목입니다.')
    } else {
      return IS_VALID()
    }
  },

  [NEW_AD_GROUP_FORM_VALIDATION_KEY.POPULATION]: (
    populationScore,
    formData,
    getState
  ) => {
    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType, goal },
        },
      },
    } = getState()

    const populationRequired = AdGroupConstraints.isPopulationRequired({
      campaignType,
      goal,
    })

    if (!populationRequired) return IS_VALID()

    const { targeting, smartMessage } = formData
    const populationText = getPopulationTitle(campaignType, goal)
    const minPopulation = getAdGroupTalkChannelPopulationMin(
      campaignType,
      goal,
      populationScore,
      targeting,
      smartMessage
    )

    if (populationScore <= minPopulation) {
      return IS_NOT_VALID(
        `${populationText}가 적어 광고그룹을 저장할 수 없습니다.`
      )
    }
    return IS_VALID()
  },

  [NEW_AD_GROUP_FORM_VALIDATION_KEY.LOCATION]: (
    locations,
    formData,
    getState
  ) => {
    const {
      adGroupV2: {
        creatingAdGroups,
        modifyAdGroup: { adGroupViewState },
      },
    } = getState()

    const isModifyAdGroup = creatingAdGroups.isEmpty()
    const { key: adGroupKey } = formData
    const { selectedLocationType } = isModifyAdGroup
      ? adGroupViewState
      : creatingAdGroups
          .find(v => v.get('key') === adGroupKey)
          .get('adGroupViewState')

    if (
      selectedLocationType === DemoGraphicEnum.Location.Type.CategoryV2.DISTRICT
    ) {
      if (locations.size === 0) {
        return IS_NOT_VALID('1개 이상 설정해야 합니다.')
      } else if (locations.size > MAXIMUM_LOCATION_SELECT_SIZE) {
        return IS_NOT_VALID(
          `행정구역은 최대 ${NumberUtils.withCommas(
            MAXIMUM_LOCATION_SELECT_SIZE
          )}개까지 설정할 수 있습니다.`
        )
      }
    }

    return IS_VALID()
  },

  [NEW_AD_GROUP_FORM_VALIDATION_KEY.BID_AMOUNT]: (
    bidAmount,
    formData,
    getState
  ) => {
    const {
      adConstraints: {
        adGroupConstraints: { pricingTypeConstraints },
      },
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType, goal },
          objective,
        },
      },
    } = getState()

    if (
      goal === GoalEnum.Type.REACH &&
      campaignType !== CampaignTypeEnum.Type.TALK_BIZ_BOARD
    ) {
      if (campaignType === CampaignTypeEnum.Type.TALK_CHANNEL) {
        const { value } = objective || {}
        if (isKakaoOfficialChannelProfileId(Number(value))) {
          const max = AdGroupConstraints.KakaoOfficialChannel.BID_AMOUNT_MAX
          const min = AdGroupConstraints.KakaoOfficialChannel.BID_AMOUNT_MIN
          const unit = AdGroupConstraints.KakaoOfficialChannel.BID_AMOUNT_UNIT

          if (
            !(
              isPositiveIntegerSequence(bidAmount) &&
              bidAmount >= min &&
              bidAmount <= max &&
              bidAmount % unit === 0
            )
          ) {
            return IS_NOT_VALID(
              `${NumberUtils.toShorten(min)}원 이상 ${NumberUtils.toShorten(
                max
              )}원 이하 ${NumberUtils.toShorten(unit)}원 단위로 입력하세요.`
            )
          }
        }
      }

      return IS_VALID() // 다음쇼핑x도달인 경우 pass
    }

    const { dailyBudget, pricingType, bidStrategy, bidStrategyTarget } =
      formData

    if (
      bidStrategy === BidStrategyEnum.Type.AUTOBID &&
      checkNotEmpty(bidStrategyTarget)
    ) {
      const { value, type } = bidStrategyTarget
      return validateAdGroupBidAmount(
        value,
        dailyBudget,
        pricingType,
        bidStrategy,
        pricingTypeConstraints,
        type
      )
    }
    return validateAdGroupBidAmount(
      bidAmount,
      dailyBudget,
      pricingType,
      bidStrategy,
      pricingTypeConstraints
    )
  },

  /**
   * 보장형인 경우 dailyBudget 체크하지 않음.
   */
  [NEW_AD_GROUP_FORM_VALIDATION_KEY.DAILY_BUDGET]: (
    dailyBudget,
    formData,
    getState
  ) => {
    const {
      adConstraints: {
        adGroupConstraints: { adGroupDailyBudgetConstraint },
      },
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType, goal },
          dailyBudget: campaignDailyBudget,
        },
      },
    } = getState()

    if (
      goal === GoalEnum.Type.REACH &&
      campaignType !== CampaignTypeEnum.Type.TALK_BIZ_BOARD &&
      !CampaignTypeEnum.isElectionCampaignType(campaignType)
    ) {
      return IS_VALID()
    }

    return validateAdGroupDailyBudget({
      adGroupDailyBudget: dailyBudget,
      campaignDailyBudget,
      adGroupDailyBudgetConstraint,
    })
  },

  [NEW_AD_GROUP_FORM_VALIDATION_KEY.TOTAL_BUDGET]: (
    totalBudget,
    formData,
    getState
  ) => {
    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType, goal },
        },
        campaignViewState: {
          freeCash: { available, totalEventFreeCash },
        },
      },
      adGroupV2: {
        creatingAdGroups,
        modifyAdGroup: { adGroupViewState },
      },
    } = getState()

    if (goal !== GoalEnum.Type.REACH) return IS_VALID()

    const isModifyAdGroup = creatingAdGroups.isEmpty()
    const { key: adGroupKey, populationScore, smartMessage } = formData
    const { totalAmount: freeCashTotalAmount = 0 } = totalEventFreeCash || {}
    const hasFreeCash = available && freeCashTotalAmount > 0

    const {
      depositCancellationFeeAgreement,
      isConfirmedTotalBudget,
      isTalkBizBoardReservedBudgetChecked,
      isElectionBudgetChecked,
      isFreeCashReservedBudgetChecked,
    } = isModifyAdGroup
      ? adGroupViewState
      : creatingAdGroups
          .find(v => v.get('key') === adGroupKey)
          .get('adGroupViewState')

    if (campaignType === CampaignTypeEnum.Type.ELECTION_2024_04) {
      if (!isElectionBudgetChecked) {
        return IS_NOT_VALID('고지사항을 확인 후 동의가 필요합니다.')
      }
    }

    if (
      campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING ||
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED
    ) {
      if (
        !depositCancellationFeeAgreement &&
        !isTalkBizBoardReservedBudgetChecked
      ) {
        return IS_NOT_VALID('고지사항을 확인 후 동의가 필요합니다.')
      }
    }

    if (
      campaignType === CampaignTypeEnum.Type.PC_TALK_BOTTOM ||
      campaignType === CampaignTypeEnum.Type.PC_TALK_RICH_POP ||
      campaignType === CampaignTypeEnum.Type.FOCUS_FULL_VIEW
    ) {
      if (!isTalkBizBoardReservedBudgetChecked) {
        return IS_NOT_VALID('고지사항을 확인 후 동의가 필요합니다.')
      }
    }

    if (
      campaignType === CampaignTypeEnum.Type.KAKAO_TV &&
      goal === GoalEnum.Type.REACH
    ) {
      const max = AdGroupConstraints.KakaoTV.PERIOD_BUDGET_AMOUNT_MAX
      const min = AdGroupConstraints.KakaoTV.PERIOD_BUDGET_AMOUNT_MIN
      const unit = AdGroupConstraints.KakaoTV.PERIOD_BUDGET_AMOUNT_UNIT

      if (
        !(
          isConfirmedTotalBudget &&
          totalBudget >= min &&
          totalBudget <= max &&
          totalBudget % unit === 0
        )
      ) {
        return IS_NOT_VALID(
          `${NumberUtils.toShorten(min)}원 이상 ${NumberUtils.toShorten(
            max
          )}원 이하 ${NumberUtils.toShorten(unit)}원 단위로 입력하세요.`
        )
      }
    }

    if (campaignType === CampaignTypeEnum.Type.TALK_CHANNEL) {
      const {
        targeting,
        messageSendingInfo,
        allAvailableDeviceType,
        schedule,
      } = formData
      const { contractCount = 0 } = messageSendingInfo || {}
      const { beginDate, beginTime } = schedule || {}

      const isAdGroupReady = isAdGroupScheduleReady({
        beginDate,
        beginTime,
        campaignType,
        goal,
      })
      /**
       *  카카오톡 채널 X 도달 광고그룹 수정인 경우에 발송시작 5분전 이후 부터는 구매발송수 validation 통과 (KAMOQA-11302)
       *  */
      if (isModifyAdGroup && !isAdGroupReady) {
        return IS_VALID()
      }

      /**
       * 스마트메시지 적용시 구매발송수는 스마트메시지 minimum count이상 예상발송 모수 이하
       * */
      if (smartMessage) {
        const talkChannelSmartMessageMinCount =
          getTalkChannelSmartMessageMinCount()

        if (populationScore < talkChannelSmartMessageMinCount) {
          return IS_NOT_VALID(
            `스마트메시지 사용 시 ${NumberUtils.withCommas(
              talkChannelSmartMessageMinCount
            )} 이상의 발송 모수가 있어야 합니다.`
          )
        } else if (
          !isConfirmedTotalBudget ||
          contractCount > populationScore ||
          contractCount < talkChannelSmartMessageMinCount
        ) {
          return IS_NOT_VALID(
            `${NumberUtils.withCommas(
              talkChannelSmartMessageMinCount
            )} 이상 ~ ${populationScore} 이하로 입력하세요.`
          )
        }
      } else if (
        populationScore >= TALK_CHANNEL_TARGETTING_MIN_CONTRACT_COUNT
      ) {
        const targetingSize = memoAdGroupSelectedTargetingSize(targeting)
        let purchaseRange = {}

        const nonTargeted = targetingSize === 0
        const adIdSending = !allAvailableDeviceType // If allAvailableDeviceType === true then `tid` else `adid` sending.

        if (nonTargeted && !adIdSending) {
          purchaseRange = {
            min: Math.max(
              TALK_CHANNEL_TARGETTING_MIN_CONTRACT_COUNT,
              populationScore
            ),
            max: NON_TARGET_MAX_CONTRACT_COUNT,
          }
        } else {
          purchaseRange = {
            min: TALK_CHANNEL_TARGETTING_MIN_CONTRACT_COUNT,
            max: populationScore,
          }
        }

        if (
          !(
            isConfirmedTotalBudget &&
            contractCount >= purchaseRange.min &&
            contractCount <= purchaseRange.max
          )
        ) {
          return IS_NOT_VALID(
            `${NumberUtils.withCommas(
              purchaseRange.min
            )} 이상 ~ ${NumberUtils.withCommas(
              purchaseRange.max
            )} 이하로 입력하세요.`
          )
        }
      } else {
        return IS_NOT_VALID(
          `${TALK_CHANNEL_TARGETTING_MIN_CONTRACT_COUNT} 이상의 발송 모수가 있어야 합니다.`
        )
      }
    }

    if (
      campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING ||
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
      campaignType === CampaignTypeEnum.Type.TALK_CHANNEL
    ) {
      if (hasFreeCash && !isFreeCashReservedBudgetChecked && totalBudget > 0) {
        return IS_NOT_VALID('고지사항을 확인 후 동의가 필요합니다.')
      }
    }

    return IS_VALID()
  },

  [NEW_AD_GROUP_FORM_VALIDATION_KEY.PLACEMENTS]: (placements, formData) => {
    const { allAvailablePlacement } = formData

    if (!allAvailablePlacement && placements.isEmpty()) {
      return IS_NOT_VALID('1개 이상 설정해야 합니다.')
    }

    return IS_VALID()
  },

  [NEW_AD_GROUP_FORM_VALIDATION_KEY.SCHEDULE]: (
    schedule,
    formData,
    getState
  ) => {
    return validateAdGroupSchedule(schedule, formData, getState)
  },

  [NEW_AD_GROUP_FORM_VALIDATION_KEY.SUB_TYPE]: (
    adGroupForm,
    formData,
    getState
  ) => {
    const {
      contract: {
        viewState: { subType, isNewContract },
      },
    } = getState()
    if (isNewContract && isEmpty(subType)) {
      return IS_NOT_VALID('필수 입력 항목입니다.')
    }
    return IS_VALID()
  },

  [NEW_AD_GROUP_FORM_VALIDATION_KEY.SCHEDULE_DATE]: (
    schedule,
    formData,
    getState
  ) => {
    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType },
        },
      },
      contract: {
        viewState: { isNewContract, selectedDate },
      },
    } = getState()

    if (CampaignTypeEnum.isContractCampaignType(campaignType)) {
      if (isNewContract && !checkNotEmpty(selectedDate)) {
        return IS_NOT_VALID('필수 입력 항목입니다.')
      }
    }

    return IS_VALID()
  },

  [NEW_AD_GROUP_FORM_VALIDATION_KEY.RETARGETING_FILTERS]: (
    retargetingFilters,
    formData,
    getState
  ) => {
    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType, goal },
        },
      },
    } = getState()

    if (
      campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG &&
      goal === GoalEnum.Type.CONVERSION
    ) {
      if (!retargetingFilters || retargetingFilters.isEmpty()) {
        return IS_NOT_VALID('1개 이상 설정해야 합니다.')
      }

      const notValidTerms = retargetingFilters
        .map(({ term }) => term)
        .some(term => term < 1 || term > 90)

      if (notValidTerms) {
        return IS_NOT_VALID('기간은 1~90일 사이 값을 입력해야 합니다.')
      }
    }

    return IS_VALID()
  },

  [NEW_AD_GROUP_FORM_VALIDATION_KEY.AD_GROUP_NAME]: name => {
    return validateAdGroupName(name)
  },
})
