import { Map } from 'immutable'
import { CREATIVE_FORM_VALIDATION_KEY } from './creativeFormValidationKey'
import {
  CATALOG_SLIDE_MAX,
  CATALOG_SLIDE_MIN,
  CREATIVE_IMAGE_TYPE,
} from '../../../utils/advertise/creativeImage'
import { IS_NOT_VALID, IS_VALID } from '../../validation'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from './creativeFormValidationMessage'
import {
  validateCreativeActionButton,
  validateCreativeLandingURL,
  validateCreativeName,
  validateCreativeOpinionProofComment,
  validateCreativeProfileName,
  validateCreativeTitle,
  validateDiscountedPrice,
} from './creativeFormValidatorV2'
import {
  checkEmpty,
  checkNotEmpty,
  checkUrl,
  checkUrlHasWhiteSpace,
  hasContinuousSharp,
  hasFullWidthWhitespace,
  hasNotAllowedEncodedSpecialCharacter,
  hasNotAllowedSpecialCharacter,
} from '../../../utils/regexUtils'
import CreativeConstraints from '../../../utils/constraints/constraints-creative'
import CreativeFormatEnum from '../../../enums/CreativeFormatEnum'
import formUtils from '../../../components/AdvertiseV2/Creative/Form/formUtils'
import { hasUnEncodedPercentage } from '../../../utils/stringUtils'

const CATALOG_FORM_VALIDATION_KEY_PATH = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_CREATE]: ['uploadedImages'],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_MODIFY]: ['profileImage'],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: ['profileName'],
  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: ['actionButton'],
  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: ['landingUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.CATALOG_ASSET_GROUPS]: ['assetGroups'],
  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: [
    'opinionProof',
    'opinion',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: ['clickTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: ['eventTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.NAME]: ['name'],
})

const CATALOG_FORM_VALIDATOR = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_CREATE]: uploadedImages => {
    if (
      !uploadedImages ||
      uploadedImages.isEmpty() ||
      uploadedImages
        .keySeq()
        .every(k => !k.startsWith(CREATIVE_IMAGE_TYPE.PROFILE))
    ) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_MODIFY]: profileImage => {
    if (!profileImage || profileImage?.isEmpty()) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: (profileName, formData) => {
    return validateCreativeProfileName(profileName, formData)
  },

  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: actionButton => {
    return validateCreativeActionButton(actionButton)
  },

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: (
    landingUrl,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
        },
      },
    } = getState()

    return validateCreativeLandingURL({ formData, campaignType })
  },

  [CREATIVE_FORM_VALIDATION_KEY.CATALOG_ASSET_GROUPS]: (
    assetGroups,
    formData
  ) => {
    if (
      !assetGroups ||
      assetGroups.count() < CATALOG_SLIDE_MIN ||
      assetGroups.count() > CATALOG_SLIDE_MAX
    ) {
      return IS_NOT_VALID(
        `슬라이드는 최소 ${CATALOG_SLIDE_MIN}개 이상 ${CATALOG_SLIDE_MAX}개 이하로 설정 가능합니다.`
      )
    }

    const assetGroupValidationResults = []
    const { mobileLandingUrl, pcLandingUrl, rspvLandingUrl } = formData

    assetGroups.forEach(assetGroup => {
      const {
        assetGroupUUID,
        image,
        title,
        description,
        price,
        discountedPrice,
        mobileLandingUrl: assetGroupMobileLandingUrl,
        pcLandingUrl: assetGroupPcLandingUrl,
        rspvLandingUrl: assetGroupRspvLandingUrl,
        isEachChecked,
        isSameUrlChecked,
      } = assetGroup
      const { profileName } = formData

      const validationResultImage =
        !image || image.isEmpty()
          ? IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
          : IS_VALID()

      const validationResultTitle = validateCreativeTitle(
        title,
        formData,
        description
      )

      const validationResultDescription =
        checkNotEmpty(description) && hasFullWidthWhitespace(description)
          ? IS_NOT_VALID(
              CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
            )
          : description === title || description === profileName
          ? IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.DIFFERENT_VALUE)
          : IS_VALID()

      const validationResultDiscountedPrice = validateDiscountedPrice({
        price,
        discountedPrice,
      })

      const validationResultLandingUrl = () => {
        const landingUrls = [
          assetGroupMobileLandingUrl,
          assetGroupPcLandingUrl,
          assetGroupRspvLandingUrl,
        ]

        if (landingUrls.every(v => checkEmpty(v))) {
          return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
        }

        if (isEachChecked && !isSameUrlChecked) {
          if (
            (!checkEmpty(pcLandingUrl) && checkEmpty(assetGroupPcLandingUrl)) ||
            (!checkEmpty(mobileLandingUrl) &&
              checkEmpty(assetGroupMobileLandingUrl))
          ) {
            return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
          }
        }

        if (
          !landingUrls.filter(v => checkNotEmpty(v)).every(v => checkUrl(v))
        ) {
          return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
        }

        if (landingUrls.some(v => checkUrlHasWhiteSpace(v))) {
          return IS_NOT_VALID(
            CREATIVE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE_BY_URL
          )
        }

        if (
          landingUrls.some(
            v => hasContinuousSharp(v) || hasNotAllowedSpecialCharacter(v)
          )
        ) {
          return IS_NOT_VALID(
            CREATIVE_FORM_VALIDATION_MESSAGE.HAS_NOT_ALLOWED_SPECIAL_CHARACTER
          )
        }

        if (
          landingUrls.some(v => {
            const { searchParams } = formUtils.Url.urlToParams(v)
            const paramString = searchParams.map(
              ({ key, value }) => `${key}=${value}`
            )
            return (
              hasNotAllowedEncodedSpecialCharacter(paramString) ||
              hasUnEncodedPercentage(paramString)
            )
          })
        ) {
          return IS_NOT_VALID(
            CREATIVE_FORM_VALIDATION_MESSAGE.HAS_INVALID_CHARACTER_WITH_PARAMETER
          )
        }

        if (
          !checkEmpty(rspvLandingUrl) &&
          checkEmpty(assetGroupRspvLandingUrl)
        ) {
          return IS_NOT_VALID(
            CREATIVE_FORM_VALIDATION_MESSAGE.HAS_DIFFERENT_DEVICE_LANDING_URL
          )
        }

        if (
          !checkEmpty(pcLandingUrl) &&
          !checkEmpty(mobileLandingUrl) &&
          (checkEmpty(assetGroupPcLandingUrl) ||
            checkEmpty(assetGroupMobileLandingUrl))
        ) {
          return IS_NOT_VALID(
            CREATIVE_FORM_VALIDATION_MESSAGE.HAS_DIFFERENT_DEVICE_LANDING_URL
          )
        }

        if (
          !checkEmpty(mobileLandingUrl) &&
          checkEmpty(assetGroupMobileLandingUrl)
        ) {
          return IS_NOT_VALID(
            CREATIVE_FORM_VALIDATION_MESSAGE.HAS_DIFFERENT_DEVICE_LANDING_URL
          )
        }

        if (
          (checkEmpty(mobileLandingUrl) &&
            !checkEmpty(assetGroupMobileLandingUrl)) ||
          (checkEmpty(pcLandingUrl) && !checkEmpty(assetGroupPcLandingUrl))
        ) {
          return IS_NOT_VALID(
            CREATIVE_FORM_VALIDATION_MESSAGE.HAS_DIFFERENT_DEVICE_LANDING_URL
          )
        }

        if (!checkEmpty(pcLandingUrl) && checkEmpty(assetGroupPcLandingUrl)) {
          return IS_NOT_VALID(
            CREATIVE_FORM_VALIDATION_MESSAGE.HAS_DIFFERENT_DEVICE_LANDING_URL
          )
        }

        return IS_VALID()
      }

      const isValid = [
        validationResultImage,
        validationResultTitle,
        validationResultDescription,
        validationResultDiscountedPrice,
        validationResultLandingUrl(),
      ].every(v => v.isValid)

      assetGroupValidationResults.push({
        assetGroupUUID,
        image: validationResultImage,
        title: validationResultTitle,
        description: validationResultDescription,
        discountedPrice: validationResultDiscountedPrice,
        landingUrl: validationResultLandingUrl(),
        isValid,
      })
    })

    const isAllValid = assetGroupValidationResults.every(v => v.isValid)

    return isAllValid
      ? IS_VALID({ assetGroupValidationResults })
      : IS_NOT_VALID('', { assetGroupValidationResults })
  },

  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: opinionProofComment => {
    return validateCreativeOpinionProofComment(opinionProofComment)
  },

  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: clickTrackerUrl => {
    if (checkNotEmpty(clickTrackerUrl)) {
      if (!checkUrl(clickTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(clickTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: eventTrackerUrl => {
    if (checkNotEmpty(eventTrackerUrl)) {
      if (!checkUrl(eventTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(eventTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.NAME]: (name, formData) => {
    const { id } = formData
    const isModify = id > 0

    return validateCreativeName(
      name,
      CreativeConstraints.getNameMaxLength({
        creativeFormat: CreativeFormatEnum.Type.CATALOG_MANUAL,
        isNew: !isModify,
      })
    )
  },
})

export { CATALOG_FORM_VALIDATION_KEY_PATH, CATALOG_FORM_VALIDATOR }
