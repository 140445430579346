import { keyMirror } from '../utils'
import assign from 'object-assign'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import { checkNotEmpty } from '../regexUtils'
import AudienceTypeEnum from '../../enums/AudienceTypeEnum'

export const COHORT_INDICATOR = keyMirror({
  PLAY: null,
  CLICK: null,
  CONVERSION: null,
  OPEN: null,
})

export const COHORT_INDICATOR_TEXT = {
  PLAY: '재생',
  CLICK: '클릭',
  CONVERSION: '전환',
  OPEN: '열람',
}

export const COHORT_OPERATION = keyMirror({
  ONLY: null,
  AND: null,
  MINUS: null,
  OR: null,
})

export const COHORT_OPERATION_TEXT = {
  ONLY: '',
  AND: '&',
  MINUS: '-',
  OR: '+',
}

export const COHORT_TARGET_DATA_MAX = 20

/**
 * target data set 조합으로 label 생성.
 */
export const createCohortTargetDataLabel = (
  firstIndicator,
  operation,
  secondIndicator,
  thirdIndicator = null,
  onlyLabel = null
) => {
  const firstIndicatorText =
    operation === COHORT_OPERATION.ONLY && onlyLabel
      ? onlyLabel
      : COHORT_INDICATOR_TEXT[firstIndicator] || ''
  const secondIndicatorText = COHORT_INDICATOR_TEXT[secondIndicator] || ''
  const thirdIndicatorText = COHORT_INDICATOR_TEXT[thirdIndicator] || ''
  const operationText = COHORT_OPERATION_TEXT[operation] || ''

  const backSideDataLabel = checkNotEmpty(thirdIndicatorText)
    ? `${secondIndicatorText} ${operationText} ${thirdIndicatorText}`
    : `${secondIndicatorText}`

  return checkNotEmpty(backSideDataLabel)
    ? `${firstIndicatorText} ${operationText} ${backSideDataLabel}`
    : `${firstIndicatorText}`
}

export const getCohortTargetDiagramClassName = (
  campaignType,
  firstIndicator,
  operation,
  secondIndicator,
  thirdIndicator
) => {
  const base = 'adtype'
  const campaignClassName = getClassNameByCampaignType(campaignType)

  const indicatorClass = v => {
    if (!Object.keys(COHORT_INDICATOR).includes(v)) return ''
    if (v === COHORT_INDICATOR.CONVERSION) return 'cv'
    return String(v).toLowerCase().charAt(0)
  }

  const operationClass = v => {
    if (!Object.keys(COHORT_OPERATION).includes(v)) return ''
    if (v === COHORT_OPERATION.ONLY) return ''
    return String(v).toLowerCase().charAt(0)
  }

  return [
    base,
    campaignClassName,
    indicatorClass(firstIndicator),
    operationClass(operation),
    indicatorClass(secondIndicator),
    indicatorClass(thirdIndicator) ? operationClass(operation) : '',
    indicatorClass(thirdIndicator),
  ]
    .filter(Boolean)
    .join('_')
}

const getClassNameByCampaignType = campaignType => {
  switch (campaignType) {
    case CampaignTypeEnum.Type.PRODUCT_CATALOG:
    case CampaignTypeEnum.Type.DISPLAY:
    case CampaignTypeEnum.Type.DAUM_SHOPPING:
    case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
    case CampaignTypeEnum.Type.SPONSORED_BOARD: {
      return 'biz'
    }
    case CampaignTypeEnum.Type.TALK_CHANNEL:
    case CampaignTypeEnum.Type.PERSONAL_MESSAGE: {
      return 'channel'
    }
    case CampaignTypeEnum.Type.TALK_BIZ_BOARD:
    case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED:
    case CampaignTypeEnum.Type.VIDEO:
    case CampaignTypeEnum.Type.KAKAO_TV:
    case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
    case CampaignTypeEnum.Type.FOCUS_FULL_VIEW: {
      return 'display'
    }
    default: {
      return ''
    }
  }
}

/**
 * 선택 가능한 operation 만 필터링해서 리턴.
 */
const createCohortOperations = ({
  only = false,
  minus = [],
  and = [],
  or = [],
}) => {
  const v1 = { [COHORT_OPERATION.ONLY]: only }
  const v2 = minus.length > 0 ? { [COHORT_OPERATION.MINUS]: minus } : {}
  const v3 = and.length > 0 ? { [COHORT_OPERATION.AND]: and } : {}
  const v4 = or.length > 0 ? { [COHORT_OPERATION.OR]: or } : {}
  return assign(v1, v2, v3, v4)
}

/**
 * -- 캠페인 유형에 따른 코호트 타겟 선택 옵션 생성 --
 *
 * ex) campaignType: DISPLAY, TALK_BIZ_BOARD / DAUM_SHOPPING, TALK_CHANNEL
 * {
 *    options: {
 *        // firstIndicator
 *        CLICK: {
 *            // operation
 *            operations: {
 *                ONLY: true,
 *                MINUS: [CONVERSION] // secondIndicator
 *            }
 *        },
 *        CONVERSION: {
 *            operations: {
 *                ONLY: true
 *            }
 *        }
 *    }
 * }
 *
 * 클릭-전환: { firstIndicator: CLICK, operation: MINUS, secondIndicator: CONVERSION }
 * 클릭(전체): { firstIndicator: CLICK, operation: ONLY }
 */
export const createCohortTargetCondition = ({ campaignType, audienceType }) => {
  switch (campaignType) {
    case CampaignTypeEnum.Type.VIDEO:
    case CampaignTypeEnum.Type.KAKAO_TV:
    case CampaignTypeEnum.Type.TALK_BIZ_BOARD:
    case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
    case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED:
    case CampaignTypeEnum.Type.FOCUS_FULL_VIEW: {
      return {
        options: {
          [COHORT_INDICATOR.PLAY]: {
            operations: createCohortOperations({
              only: true,
              minus: [{ secondIndicator: COHORT_INDICATOR.CLICK }],
              and: [{ secondIndicator: COHORT_INDICATOR.CLICK }],
            }),
          },
          [COHORT_INDICATOR.CLICK]: {
            operations: createCohortOperations({
              only: true,
              minus: [
                { secondIndicator: COHORT_INDICATOR.PLAY },
                { secondIndicator: COHORT_INDICATOR.CONVERSION },
              ],
            }),
          },
          [COHORT_INDICATOR.CONVERSION]: {
            operations: createCohortOperations({ only: true }),
          },
        },
        defaultOption:
          campaignType === CampaignTypeEnum.Type.DISPLAY
            ? COHORT_INDICATOR.CLICK
            : COHORT_INDICATOR.PLAY,
      }
    }

    case CampaignTypeEnum.Type.PERSONAL_MESSAGE:
    case CampaignTypeEnum.Type.TALK_CHANNEL: {
      if (audienceType === AudienceTypeEnum.Type.DISPLAY) {
        return {
          options: {
            [COHORT_INDICATOR.CONVERSION]: {
              operations: createCohortOperations({ only: true }),
            },
          },
          defaultOption: COHORT_INDICATOR.CONVERSION,
        }
      }

      return {
        options: {
          [COHORT_INDICATOR.OPEN]: {
            operations: createCohortOperations({
              only: true,
              minus: [
                {
                  secondIndicator: COHORT_INDICATOR.CLICK,
                  thirdIndicator: COHORT_INDICATOR.PLAY,
                },
              ],
            }),
          },
          [COHORT_INDICATOR.CLICK]: {
            operations: createCohortOperations({
              only: true,
              minus: [{ secondIndicator: COHORT_INDICATOR.PLAY }],
              and: [{ secondIndicator: COHORT_INDICATOR.PLAY }],
            }),
          },
          [COHORT_INDICATOR.PLAY]: {
            operations: createCohortOperations({
              only: true,
              minus: [{ secondIndicator: COHORT_INDICATOR.CLICK }],
            }),
          },
        },
        defaultOption: COHORT_INDICATOR.OPEN,
      }
    }

    case CampaignTypeEnum.Type.PRODUCT_CATALOG:
    case CampaignTypeEnum.Type.DISPLAY:
    case CampaignTypeEnum.Type.SPONSORED_BOARD:
    case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
    case CampaignTypeEnum.Type.DAUM_SHOPPING: {
      return {
        options: {
          [COHORT_INDICATOR.CLICK]: {
            operations: createCohortOperations({
              only: true,
              minus: [{ secondIndicator: COHORT_INDICATOR.CONVERSION }],
            }),
          },
          [COHORT_INDICATOR.CONVERSION]: {
            operations: createCohortOperations({ only: true }),
          },
        },
        defaultOption: COHORT_INDICATOR.CLICK,
      }
    }

    default: {
      return {
        options: {},
      }
    }
  }
}
