import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import GoalEnum from '../../enums/GoalEnum'
import ObjectiveTypeEnum from '../../enums/ObjectiveTypeEnum'
import BudgetEnum from '../../enums/BudgetEnum'

const DashboardConstraints = {
  Campaign: {
    isAvailableObjective: ({ campaignType, goal }) =>
      campaignType !== CampaignTypeEnum.Type.DAUM_SHOPPING &&
      goal !== GoalEnum.Type.VISITING,

    isExtraInfos: ({ campaignType }) =>
      CampaignTypeEnum.isContractCampaignType(campaignType),

    isAvailableDailyBudget: ({ campaignType, goal }) =>
      goal === GoalEnum.Type.VISITING ||
      goal === GoalEnum.Type.CONVERSION ||
      goal === GoalEnum.Type.VIEW,

    isAvailableTotalBudget: ({ campaignType, goal }) =>
      campaignType !== CampaignTypeEnum.Type.KAKAO_TV &&
      goal === GoalEnum.Type.REACH,

    isAvailableTotalBudgetWithRemainBudget: ({ campaignType, goal }) =>
      campaignType === CampaignTypeEnum.Type.KAKAO_TV &&
      goal === GoalEnum.Type.REACH,

    isAvailableOperationInfo: ({ campaignType, goal }) =>
      (campaignType !== CampaignTypeEnum.Type.TALK_CHANNEL &&
        goal !== GoalEnum.Type.REACH) ||
      campaignType === CampaignTypeEnum.Type.KAKAO_TV,

    isBasedOnTalkChannel: ({ campaignType, goal, objectiveType }) =>
      campaignType === CampaignTypeEnum.Type.DISPLAY &&
      goal === GoalEnum.Type.CONVERSION &&
      objectiveType === ObjectiveTypeEnum.Type.TALK_CHANNEL,

    budgetType: ({ campaignType }) => {
      switch (campaignType) {
        case CampaignTypeEnum.Type.TALK_CHANNEL:
        case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED:
        case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
        case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
        case CampaignTypeEnum.Type.PERSONAL_MESSAGE:
        case CampaignTypeEnum.Type.DAUM_SHOPPING:
        case CampaignTypeEnum.Type.FOCUS_FULL_VIEW:
        case CampaignTypeEnum.Type.ELECTION_2024_04: {
          return BudgetEnum.Type.TOTAL
        }

        default: {
          return BudgetEnum.Type.DAILY
        }
      }
    },
  },

  AdGroup: {
    // 입찰금액
    isAvailableBidAmount: ({ campaignType, goal }) =>
      goal === GoalEnum.Type.VISITING ||
      (campaignType === CampaignTypeEnum.Type.DISPLAY &&
        goal === GoalEnum.Type.CONVERSION) ||
      goal === GoalEnum.Type.VIEW,

    // 단가
    isAvailableBidAmountCost: ({ campaignType, goal }) =>
      ((campaignType === CampaignTypeEnum.Type.TALK_CHANNEL ||
        campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING) &&
        goal === GoalEnum.Type.REACH) ||
      (campaignType === CampaignTypeEnum.Type.KAKAO_TV &&
        goal === GoalEnum.Type.REACH),

    isAvailableDailyBudget: ({ campaignType, goal }) =>
      goal === GoalEnum.Type.VISITING ||
      goal === GoalEnum.Type.CONVERSION ||
      goal === GoalEnum.Type.VIEW,

    isAvailableTotalBudget: ({ campaignType, goal }) =>
      goal === GoalEnum.Type.REACH,

    isAvailableCombinedPopulation: ({ campaignType, goal, objectiveType }) => {
      const a =
        campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
        goal === GoalEnum.Type.REACH
      const b =
        campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD &&
        goal === GoalEnum.Type.VISITING
      const c =
        campaignType === CampaignTypeEnum.Type.DISPLAY &&
        goal === GoalEnum.Type.VISITING
      const d =
        campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
        goal === GoalEnum.Type.CONVERSION
      const e =
        campaignType === CampaignTypeEnum.Type.DISPLAY &&
        goal === GoalEnum.Type.CONVERSION &&
        objectiveType === ObjectiveTypeEnum.Type.TALK_CHANNEL

      return a || b || c || d || e
    },

    isAvailableAudienceTarget: ({ campaignType, goal }) =>
      campaignType !== CampaignTypeEnum.Type.DAUM_SHOPPING &&
      !CampaignTypeEnum.isContractCampaignType(campaignType),
    isAvailableKakaoContentsTarget: ({ campaignType, goal }) =>
      campaignType === CampaignTypeEnum.Type.KAKAO_TV,

    isAvailableOperationInfo: ({ campaignType, goal }) =>
      (campaignType !== CampaignTypeEnum.Type.TALK_CHANNEL &&
        goal !== GoalEnum.Type.REACH) ||
      campaignType === CampaignTypeEnum.Type.KAKAO_TV,

    isAvailableSendingInfo: ({ campaignType, goal }) =>
      campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
      goal === GoalEnum.Type.REACH,

    isAvailableLongTermSending: ({ campaignType, goal }) =>
      campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
      goal === GoalEnum.Type.REACH,

    isAvailableSmartMessage: ({ campaignType, goal }) =>
      campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
      goal === GoalEnum.Type.REACH,

    isAvailableProductCatalog: ({ campaignType, goal }) =>
      campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG &&
      goal === GoalEnum.Type.CONVERSION,

    isAvailablePeriodSetting: ({ campaignType }) =>
      campaignType !== CampaignTypeEnum.Type.PERSONAL_MESSAGE,
  },

  Creative: {
    isAvailableSchedule: ({ campaignType, goal }) =>
      campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING,

    isAvailableOnOff: ({ campaignType, goal }) =>
      !(
        campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
        goal === GoalEnum.Type.REACH
      ),

    isAvailableKakaoTvTarget: ({ campaignType }) =>
      campaignType === CampaignTypeEnum.Type.KAKAO_TV,
  },
}

export default DashboardConstraints
