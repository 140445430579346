import { keyMirror } from '../utils/utils'

const AdAccountExtraEnum = {
  Type: keyMirror({
    TALK_BIZ_BOARD_EXPANDABLE: null,
    ACCOUNT_ID_UPLOAD: null,
    MESSAGE_AD_PARTNER: null,
    MESSAGE_AD_SELLER: null,
    ATM_TARGET: null,
    SKAN_PROTOCOL: null,
    KCLID: null,
  }),

  Name: {
    TALK_BIZ_BOARD_EXPANDABLE: '비즈보드 익스팬더블',
    ACCOUNT_ID_UPLOAD: '어카운트 아이디 업로드',
    MESSAGE_AD_PARTNER: '메시지 광고 제휴사',
    MESSAGE_AD_SELLER: '메시지 광고 가맹점주',
    ATM_TARGET: 'ATM 타겟',
    SKAN_PROTOCOL: 'SKAdNetwork 옵션 설정',
    KCLID: 'kclid 옵션 설정',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  isAccountUploadType(extra) {
    return Object.keys(extra).includes(
      AdAccountExtraEnum.Type.ACCOUNT_ID_UPLOAD
    )
  },
}

export default AdAccountExtraEnum
