import { keyMirror } from '../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { checkNotEmpty } from '../../utils/regexUtils'
import { setCreativeCommonByKeyPath } from './mCreativeCommonV2'
import { handleCreativeFormExceptionV2 } from './creativeActions/aCreativeCommonV2'
import { ShowcaseApi } from '../../modules-api/showcase/showcaseApi'
import { showErrorMessage } from '../../utils/alertUtils'
import { hideLoading, showLoading } from '../common/mLoading'

const DynamicCatalog = keyMirror(
  {
    /**
     *  카탈로그 관련 Action
     */
    SET_CATALOG_LIST: null,

    /**
     * 상품세트 관련 Action
     */
    SET_CATALOG: null,
    SET_PRODUCT_SET: null,
    INIT_PRODUCT_SET: null,
    SET_PRODUCT_SET_DETAIL_ITEMS: null,
    SET_PRODUCT_SET_BIZBOARD: null,
    INIT_PRODUCT_PREVIEW: null,
    CLEAR: null,
  },
  'DYNAMIC_CATALOG'
)

const initialState = fromJS({
  catalogs: null,
  productCatalog: {}, // 카탈로그
  productSet: {}, // 상품세트
  productPreview: {
    productSetDetailItems: [], // 상품세트 하위 상품목록
    productBizboardItem: null,
  },
})

export default createReducer(initialState, {
  [DynamicCatalog.SET_CATALOG_LIST]: (state, { data }) =>
    state.set('catalogs', fromJS(data)),
  [DynamicCatalog.SET_CATALOG]: (state, { data }) =>
    state.set('productCatalog', fromJS(data)),

  [DynamicCatalog.SET_PRODUCT_SET_DETAIL_ITEMS]: (state, { data }) =>
    state.setIn(['productPreview', 'productSetDetailItems'], fromJS(data)),

  [DynamicCatalog.SET_PRODUCT_SET_BIZBOARD]: (state, { data }) =>
    state.setIn(['productPreview', 'productBizboardItem'], fromJS(data)),

  [DynamicCatalog.INIT_PRODUCT_PREVIEW]: state =>
    state.set(['productPreview'], initialState.get('productPreview')),

  [DynamicCatalog.SET_PRODUCT_SET]: (state, { data }) =>
    state.set('productSet', fromJS(data)),

  [DynamicCatalog.INIT_PRODUCT_SET]: state =>
    state.set('productSet', initialState.get('productSet')),

  [DynamicCatalog.CLEAR]: () => initialState,
})

export const clearDynamicCatalog = () => ({
  type: DynamicCatalog.CLEAR,
})

export const setDynamicCatalogList = data => ({
  type: DynamicCatalog.SET_CATALOG_LIST,
  data,
})

export const setDynamicCatalogProductInfo = data => ({
  type: DynamicCatalog.SET_CATALOG,
  data,
})

export const setDynamicCatalogProductDetailItems = data => ({
  type: DynamicCatalog.SET_PRODUCT_SET_DETAIL_ITEMS,
  data,
})

export const setDynamicCatalogProductBizboard = data => ({
  type: DynamicCatalog.SET_PRODUCT_SET_BIZBOARD,
  data,
})

export const initDynamicCatalogProductDetailItems = () => ({
  type: DynamicCatalog.INIT_PRODUCT_PREVIEW,
})

export const setDynamicCatalogProductSetInfo = data => ({
  type: DynamicCatalog.SET_PRODUCT_SET,
  data,
})

export const initDynamicCatalogProductSetInfo = () => ({
  type: DynamicCatalog.INIT_PRODUCT_SET,
})

export const showDynamicCatalogAndProductSetIsNotValidError = () => {
  showErrorMessage(
    '선택한 카탈로그 또는 상품 세트가 삭제, 관리자 정지 등 유효하지 않은 상태로 요청한 작업을 실행할 수 없습니다. 카탈로그 관리에서 카탈로그 또는 상품 세트의 상태를 확인하세요.'
  )
}

/**
 * 카탈로그 단건 조회
 * @param adAccountId
 * @param creativeId
 * @param catalogId
 */
export function fetchCreativeDynamicCatalog({
  adAccountId,
  creativeId,
  catalogId,
}) {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())

      const response = await api.showcase.fetchCatalogInfoById(
        adAccountId,
        catalogId
      )

      dispatch(
        setCreativeCommonByKeyPath(
          ['campaign', 'objective', 'description'],
          response.data.name
        )
      )

      const { availability, deletedDate } = response.data

      response.data['isDeleted'] = !availability && checkNotEmpty(deletedDate)

      dispatch(setDynamicCatalogProductInfo(response.data))
    } catch (e) {
      dispatch(
        handleCreativeFormExceptionV2({
          ...(e?.response?.data || {}),
          creativeId,
          adAccountId,
        })
      )
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * 소재 수정 batch, 기존 소재 불러오기 시점에 카탈로그, 상품세트, 상품세트 하위 상품 조회하여 정보 저장 및 에러 처리
 * @param adAccountId
 * @param creativeId
 * @param catalogId
 * @param catalogProductSetId
 * @param onSuccess
 * @param onError
 */
export function fetchCreativeDynamicCatalogModifyBatch({
  adAccountId,
  creativeId,
  catalogId,
  catalogProductSetId,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch, getState, api) => {
    try {
      // 카탈로그 단건 조회
      const catalogResponse = await api.showcase.fetchCatalogInfoById(
        adAccountId,
        catalogId
      )

      const {
        availability: catalogAvailability,
        deletedDate,
        name,
      } = catalogResponse.data

      catalogResponse.data['isDeleted'] =
        !catalogAvailability && checkNotEmpty(deletedDate)

      dispatch(setDynamicCatalogProductInfo(catalogResponse.data))

      dispatch(
        setCreativeCommonByKeyPath(
          ['campaign', 'objective', 'description'],
          name
        )
      )

      if (catalogAvailability) {
        // 상품세트 단건 조회
        const productSetResponse =
          await api.showcase.fetchAllCatalogProductSets(
            adAccountId,
            catalogProductSetId
          )

        const {
          availability: productSetAvailability,
          deletedDate: productSetDeletedDate,
        } = productSetResponse.data

        productSetResponse.data['isDeleted'] =
          !productSetAvailability && checkNotEmpty(productSetDeletedDate)

        dispatch(setDynamicCatalogProductSetInfo(productSetResponse.data))

        if (productSetAvailability) {
          // 권한, 삭제 상태등이 모두 정상 조회될 경우 상품세트 하위 상품 목록 조회
          const productsResponse = await ShowcaseApi.fetchProductSetProducts({
            adAccountId,
            productSetId: catalogProductSetId,
            size: 3,
          })

          dispatch(
            setDynamicCatalogProductDetailItems(productsResponse.data.content)
          )

          const bizboardResponse = await ShowcaseApi.fetchProductSetBizboard({
            adAccountId,
            productSetId: catalogProductSetId,
          })

          dispatch(
            setDynamicCatalogProductBizboard(bizboardResponse.data.content[0])
          )

          onSuccess()
        } else {
          showDynamicCatalogAndProductSetIsNotValidError()
          onError()
        }
      } else {
        showDynamicCatalogAndProductSetIsNotValidError()
        onError()
      }
    } catch (e) {
      dispatch(
        handleCreativeFormExceptionV2({
          ...(e?.response?.data || {}),
          creativeId,
          adAccountId,
        })
      )
      onError()
    }
  }
}

export function fetchCampaignCatalogList({ adAccountId }) {
  return async (dispatch, getState, api) => {
    try {
      const { data } = await api.showcase.fetchAuthorityCatalogList(adAccountId)
      dispatch(setDynamicCatalogList(data))
    } catch (error) {
      console.log(error)
      // dispatch(clearDynamicCatalog())

      if (!error || !error.response || !error.response.data) return
      const { errorCode, message } = error.response.data

      switch (Number(errorCode)) {
        default:
          return showErrorMessage(message)
      }
    }
  }
}
