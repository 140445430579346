import React from 'react'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import GoalEnum from '../../enums/GoalEnum'
import {
  openPopup,
  openPopupByProxy,
  POPUP_KEY,
} from '../../modules/common/mPopup'
import {
  showErrorMessage,
  showLoadingMessage,
  showPromiseMessage,
} from '../../utils/alertUtils'
import { getAdAccountSelectorItems } from '../../modules/common/mAdAccountListSelector'
import { DashboardToastMessage } from '../../utils/dashboard/dashboardToastMessage'
import {
  initDashboardSummaryByStorageAndParams,
  selectDashboardSummary,
} from '../../modules/dashboardV3/mDashboardSummary'
import DashboardEnumV2 from '../../enums/DashboardEnumV2'
import {
  hideDashboardOnOffSpinnerLoading,
  invalidateDashboardAdAccountInfo,
  setDashboardByUrlSearchParams,
} from '../../modules/dashboardV3/mDashboardCommon'
import { initDashboardCalendarByStorage } from '../../modules/dashboardV3/mDashboardCalendar'
import {
  hideLoading,
  LOADING_KEY,
  showLoading,
} from '../../modules/common/mLoading'
import RequestLock from '../../utils/requestLock'
import {
  adAccountPauseWarningDialog,
  adAccountResumeWarningForTalkChannelReachDialog,
} from '../../components/DashboardV3/Dialog/AdAccountDialog'
import { toast } from 'react-hot-toast'
import { getDashboardExceptionMessage } from '../../modules/dashboardV3/dashboardException'
import {
  initDashboardTableRowIdSetByStorage,
  initDashboardTableSortByStorage,
} from '../../modules/dashboardV3/mDashboardTable'
import { MomentToasterButton } from '../../components/MomentToaster/MomentToaster'

/**
 * 최초 진입 시, 광고계정 변경 시
 */
export function deployDashboard({ adAccountId }) {
  return async dispatch => {
    dispatch(showLoading(LOADING_KEY.DASHBOARD_V3))

    try {
      /**
       * INITIALIZE PHASE
       */
      await dispatch(invalidateDashboardAdAccountInfo({ adAccountId }))

      // INIT BY STORAGE
      dispatch(initDashboardTableSortByStorage({ adAccountId }))
      dispatch(initDashboardTableRowIdSetByStorage({ adAccountId }))
      dispatch(initDashboardCalendarByStorage({ adAccountId }))

      // INIT BY PARAMS
      const urlSearchParams = new URL(window.location.href).searchParams
      dispatch(
        initDashboardSummaryByStorageAndParams({ adAccountId, urlSearchParams })
      )
      dispatch(setDashboardByUrlSearchParams({ urlSearchParams }))
    } catch (e) {
      console.log(e.message)
    } finally {
      dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3))
    }
  }
}

export function selectDashboardAdAccountSummary() {
  return dispatch => {
    dispatch(
      selectDashboardSummary({
        dashboardType: DashboardEnumV2.Type.AD_ACCOUNT,
      })
    )
  }
}

function checkOnOffEffectAdAccount({
  adAccountId,
  active,
  loadingMessage,
  onOK,
}) {
  return async (dispatch, getState, api) => {
    try {
      await RequestLock.acquire({
        key: 'checkOnOffEffectAdAccount',
        executor: async done => {
          try {
            const toastId = showLoadingMessage(loadingMessage)

            const response = await api.dashboard.preCheckAdAccountOnOff(
              adAccountId,
              active ? 'ON' : 'OFF'
            )

            const campaignTypeGoals = response.data || []

            const has = ({ campaignType, goal }) =>
              campaignTypeGoals.some(v => {
                const a = v.campaignType === campaignType
                const b = v.goal === goal
                if (campaignType && goal) return a && b
                if (campaignType && !goal) return a
                if (!campaignType && goal) return b
                return false
              })

            const hasTalkChannelReach = has({
              campaignType: CampaignTypeEnum.Type.TALK_CHANNEL,
              goal: GoalEnum.Type.REACH,
            })

            const hasDaumShoppingReach = has({
              campaignType: CampaignTypeEnum.Type.DAUM_SHOPPING,
              goal: GoalEnum.Type.REACH,
            })

            const hasTalkBizBoardReserved = has({
              campaignType: CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED,
              goal: GoalEnum.Type.REACH,
            })

            const hasPersonalMessageReach = has({
              campaignType: CampaignTypeEnum.Type.PERSONAL_MESSAGE,
              goal: GoalEnum.Type.REACH,
            })

            const hasElection2024 = has({
              campaignType: CampaignTypeEnum.Type.ELECTION_2024_04,
              goal: GoalEnum.Type.REACH,
            })

            const hasPcTalkBottom = has({
              campaignType: CampaignTypeEnum.Type.PC_TALK_BOTTOM,
              goal: GoalEnum.Type.REACH,
            })

            const hasPcTalkRichPop = has({
              campaignType: CampaignTypeEnum.Type.PC_TALK_RICH_POP,
              goal: GoalEnum.Type.REACH,
            })

            const hasFocusFullView = has({
              campaignType: CampaignTypeEnum.Type.FOCUS_FULL_VIEW,
              goal: GoalEnum.Type.REACH,
            })

            if (active) {
              if (hasTalkChannelReach) {
                dispatch(
                  openPopupByProxy(
                    POPUP_KEY.SIMPLE_POPUP,
                    adAccountResumeWarningForTalkChannelReachDialog({
                      onOK: () => onOK({ toastId }),
                      onCancel: () =>
                        dispatch(hideDashboardOnOffSpinnerLoading()),
                    })
                  )
                )
                toast.remove(toastId)
                return
              }
            } else {
              if (
                hasTalkChannelReach ||
                hasDaumShoppingReach ||
                hasTalkBizBoardReserved ||
                hasPersonalMessageReach ||
                hasElection2024 ||
                hasPcTalkBottom ||
                hasPcTalkRichPop ||
                hasFocusFullView
              ) {
                dispatch(
                  openPopupByProxy(
                    POPUP_KEY.SIMPLE_POPUP,
                    adAccountPauseWarningDialog({
                      onOK: () => onOK({ toastId }),
                      onCancel: () =>
                        dispatch(hideDashboardOnOffSpinnerLoading()),
                      hasTalkChannelReach,
                      hasDaumShoppingReach,
                      hasTalkBizBoardReserved,
                      hasPersonalMessageReach,
                      hasElection2024,
                      hasPcTalkRichPop,
                      hasPcTalkBottom,
                      hasFocusFullView,
                    })
                  )
                )
                toast.remove(toastId)
                return
              }
            }

            onOK({ toastId })
          } catch (e) {
            console.log(e.message)
          } finally {
            done()
          }
        },
      })
    } catch (e) {
      if (e?.isLocked) {
        console.warn('checkOnOffEffectAdAccount is locked')
      }
    }
  }
}

export function modifyAdAccountOnOff({ adAccountId, active = false }) {
  return async (dispatch, getState, api) => {
    const subject = '광고계정 ON/OFF'
    const loadingMessage = DashboardToastMessage.modifyLoading(subject)

    const hasAdGroupSendingDetail = ({ successCount, failureCount }) =>
      successCount + failureCount > 0

    await dispatch(
      checkOnOffEffectAdAccount({
        adAccountId,
        active,
        loadingMessage,
        onOK: async ({ toastId }) => {
          try {
            await showPromiseMessage({
              existToastId: toastId,
              promiseFn: () =>
                api.dashboard.modifyAdAccountOnOff(adAccountId, active),
              onLoading: () => {
                return loadingMessage
              },
              onSuccess: response => {
                dispatch(invalidateDashboardAdAccountInfo({ adAccountId }))

                // 광고계정 선택상자 갱신
                dispatch(getAdAccountSelectorItems(adAccountId))

                // 그룹 메시지 상태 변경 정보
                const adGroupSendingResult = response.data || {}

                if (hasAdGroupSendingDetail(adGroupSendingResult)) {
                  return DashboardToastMessage.changeOnOffAdGroupSendingStatusResult(
                    adGroupSendingResult
                  )
                } else {
                  return DashboardToastMessage.modifySuccess(subject)
                }
              },
              renderButtonOnSuccess: response => {
                /**
                 * requests id[]
                 * successes: {
                 *    [id]: object
                 * }
                 * failures {
                 *    [id]: { errorCode: number, errorMessage: string }
                 * }
                 * successCount {number}
                 * failureCount {number}
                 */
                const adGroupSendingResult = response.data || {}

                if (hasAdGroupSendingDetail(adGroupSendingResult)) {
                  return (
                    <MomentToasterButton
                      label="자세히 보기"
                      onClick={() => {
                        dispatch(
                          openPopup(
                            POPUP_KEY.DASHBOARD_PROMISE_MESSAGE_DETAIL,
                            {
                              requestName: '광고그룹 발송 상태 변경',
                              resultIdContextName: '광고그룹',
                              ...adGroupSendingResult,
                            }
                          )
                        )
                      }}
                    />
                  )
                }

                return null
              },
              onError: e => {
                return (
                  getDashboardExceptionMessage({ e }) ||
                  DashboardToastMessage.modifyFailure(subject)
                )
              },
              onFinally: () => {
                dispatch(hideDashboardOnOffSpinnerLoading())
              },
            })
          } catch (e) {
            showErrorMessage(
              getDashboardExceptionMessage({ e }) ||
                DashboardToastMessage.modifyFailure(subject)
            )
            console.log(e.message)
          }
        },
      })
    )
  }
}
