import PopupProxy from '../../Popup/Common/PopupProxy'
import React, { Fragment } from 'react'

const creativeUnavailableOnOffChangeWarningDialog = ({ onOK }) => {
  return (
    <PopupProxy hasSecondaryButton={true} primaryButtonFunc={onOK}>
      <strong className="tit_layer">ON/OFF 상태 변경 불가 소재</strong>
      <p className="txt_layer">
        ON/OFF 상태 변경이 불가한 소재가 포함되어 있습니다.
        <br />
        해당 소재를 제외하고 계속 진행하시겠습니까?
      </p>
    </PopupProxy>
  )
}

const creativeResumeNoScheduleWarningDialog = ({ noScheduledCreatives }) => {
  const message =
    noScheduledCreatives.count() > 1
      ? '노출 가능한 시간이 없는 소재가 포함되어 있습니다.'
      : '노출 가능한 시간이 없는 소재입니다.'

  return (
    <PopupProxy>
      <strong className="tit_layer">광고 재개 불가</strong>
      <p className="txt_layer">
        {message}
        <br />
        소재 기간을 입력해야 합니다.
        {noScheduledCreatives.count() > 1 && (
          <>
            <br />
            <br />
            {noScheduledCreatives.map(({ name }, i) => (
              <Fragment key={i}>
                {name}
                <br />
              </Fragment>
            ))}
          </>
        )}
      </p>
    </PopupProxy>
  )
}

const creativePauseWarningDialog = ({ onOK, onCancel }) => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      primaryButtonFunc={onOK}
      onDismiss={onCancel}>
      <strong className="tit_layer">광고 일시 중지</strong>
      <p className="txt_layer">광고 집행을 일시 중지하시겠습니까?</p>
      <div className="adbox_scroll">
        <div className="inner_adbox_scroll">
          <strong className="tit_adbox">[개인화 메시지 유형]</strong>
          <p className="txt_adbox">
            발송 중이거나 발송 예정인 메시지가 있는 경우 발송이 완전히
            취소됩니다. (발송 재개 불가)
          </p>
        </div>
      </div>
    </PopupProxy>
  )
}

const creativeTrackingUrlKeyDuplicateDialog = ({ onOk }) => {
  return (
    <PopupProxy hasSecondaryButton={true} primaryButtonFunc={onOk}>
      <p className="tit_layer">트래킹 URL 확인</p>
      <p className="txt_layer">
        동일한 매개변수가 중복으로 입력되어있습니다. 트래킹 URL을 확인하세요.
      </p>
    </PopupProxy>
  )
}

const creativeTrackingUrlValueNullDialog = ({ onOk }) => {
  return (
    <PopupProxy hasSecondaryButton={true} primaryButtonFunc={onOk}>
      <p className="tit_layer">트래킹 URL 확인</p>
      <p className="txt_layer">
        매개변수 Value 중 입력되지 않은 항목이 있습니다. 트래킹 URL을
        확인하세요.
      </p>
    </PopupProxy>
  )
}

const creativeLandingUrlAlertDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">소재 저장 불가 </strong>
      <p className="txt_layer">
        랜딩 URL이 입력되지 않았습니다. <br />
        입력 후 다시 진행하세요.
      </p>
    </PopupProxy>
  )
}

export {
  creativeUnavailableOnOffChangeWarningDialog,
  creativeResumeNoScheduleWarningDialog,
  creativePauseWarningDialog,
  creativeTrackingUrlKeyDuplicateDialog,
  creativeTrackingUrlValueNullDialog,
  creativeLandingUrlAlertDialog,
}
