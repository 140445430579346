import { isMomentRootPath } from '../app/services/kakaoMoment'
import DashboardRouter from '../../components/DashboardV3/dashboardRouter'
import AudienceTypeEnum from '../../enums/AudienceTypeEnum'
import { SERVICE_TYPE } from '../loungeBoardFetchUtils'
import { KakaoLoungeBoard } from '../app/services/kakaoLoungeBoard'

/**
 * URL 에 adAccount Id 가 포함되어 있지 않은 경우 이 루틴을 타게 된다.
 * 그 외 특정화된 pathname 이 있다면 그 pathname 으로 리턴한다. (ex: /adaccount/all)
 * @param params
 * @param params.adAccountId - storedAccount | adAccountListSelector.first()
 * @param params.redirectHome {boolean} - 강제 홈으로 이동
 */
export const toMomentInitialPath = ({ adAccountId, redirectHome = false }) => {
  if (redirectHome || isMomentRootPath()) {
    return toMyAdAccountListPath()
  }

  // 광고계정 없이 dashboard path 입력 시. 자동으로 첫 번째 광고계정 선택된 상태로 대시보드 이동
  if (['/dashboard', '/dashboard/'].includes(window.location.pathname)) {
    return DashboardRouter.Path.AdAccount({ adAccountId })
  }

  return window.location.pathname + window.location.search
}

/**
 * ERROR
 */
export const toErrorPath = () => '/error'
export const toServiceUnavailablePath = () => '/service_unavailable'
export const toInvalidApproachPath = (errorCode = null) => {
  return errorCode ? `/invalid_approach/${errorCode}` : '/invalid_approach'
}
export const toNotFoundPath = () => '/not_found'
export const toWithdrawSuccessPath = () => '/success_withdraw'
export const toNoticeBoardPath = () => '/noticeboard'

export const toLoungeBoardNoticePath = () =>
  `${KakaoLoungeBoard.getHost()}/bulletin/list?serviceType=${SERVICE_TYPE}`

export const toLoungeBoardNoticeDetailPath = noticeid =>
  `${KakaoLoungeBoard.getHost()}/bulletin/${noticeid}?serviceType=${SERVICE_TYPE}`

// CASH
export const redirectCashPath = () => '/cashResult.html'

/**
 * my
 */
export const toRequestAdAccountMemberPath = () => '/adaccount/request'
export const toMyAdAccountListPath = () => '/adaccount/all'
export const toMyInfoPath = () => '/myinfo'

/**
 * create adAccount or company
 */
export const toCreateAdAccountPath = () => '/adaccount/all/create'

/**
 * settings
 */
export const toSettingsReviewPath = adAccountId =>
  `/${adAccountId}/settings/review`
export const toSettingsReviewDetailPath = (adAccountId, creativeCategoryId) =>
  `/${adAccountId}/settings/reviewdetail/${creativeCategoryId}`
export const toSettingsCashPath = adAccountId => `/${adAccountId}/settings/cash`
export const toSettingsPaymentCardPath = adAccountId =>
  `/${adAccountId}/settings/paymentcard`
export const toSettingsInvoicePath = adAccountId =>
  `/${adAccountId}/settings/account/invoice`
export const toSettingsBusinessRightPath = adAccountId =>
  `/${adAccountId}/settings/account/businessright`
export const toSettingsAccountMemberPath = adAccountId =>
  `/${adAccountId}/settings/account/member`
export const toSettingsAccountInfoPath = adAccountId =>
  `/${adAccountId}/settings/account/info`
export const toInvitePath = () => '/invite'

/**
 * targeting
 */
// plus friend group files
export const toPlusFriendGroupPath = adAccountId =>
  `/${adAccountId}/targeting/plusfriendgroup`
export const toPlusFriendGroupDetailPath = (adAccountId, plusFriendGroupId) =>
  `/${adAccountId}/targeting/plusfriendgroup/${plusFriendGroupId}`

// customer files
export const toCustomerFilesListPath = adAccountId =>
  `/${adAccountId}/targeting/customerfilesupload`
export const toCustomerFilesUploadDetailPath = (adAccountId, customerFileId) =>
  `/${adAccountId}/targeting/customerfilesupload/${customerFileId}`

// pixel & sdk
export const toTargetingPixelPath = adAccountId =>
  `/${adAccountId}/linkage/pixel`
export const toPixelAndSdkDetailPath = (adAccountId, trackId) =>
  `/${adAccountId}/linkage/pixel/${trackId}`

// cohort
export const toTargetingCohortPath = (
  adAccountId,
  audienceType = AudienceTypeEnum.Type.DISPLAY
) => `/${adAccountId}/targeting/cohort?audienceType=${audienceType}`
export const toCreateTargetingCohortPath = (adAccountId, audienceType) =>
  `/${adAccountId}/targeting/cohort/create?audienceType=${audienceType}`
export const toUpdateTargetingCohortPath = (adAccountId, id, audienceType) =>
  `/${adAccountId}/targeting/cohort/${id}/update?audienceType=${audienceType}`

// audience
export const toAudienceManagementPath = (
  adAccountId,
  audienceType = AudienceTypeEnum.Type.DISPLAY
) => `/${adAccountId}/targeting/audience?audienceType=${audienceType}`
export const toCreateAudiencePath = (adAccountId, audienceType) =>
  `/${adAccountId}/targeting/audience/create?audienceType=${audienceType}`
export const toAudienceDetailPath = (adAccountId, audienceId) =>
  `/${adAccountId}/targeting/audience/${audienceId}`
export const toModifyAudiencePath = (adAccountId, audienceId) =>
  `/${adAccountId}/targeting/audience/${audienceId}/modify`

/**
 * report v2
 */
export const toReportPath = adAccountId => `/${adAccountId}/report`
export const toReportCreatePath = adAccountId => `/${adAccountId}/report/create`
export const toReportModifyPath = (adAccountId, id) =>
  `/${adAccountId}/report/${id}`

/**
 * adView
 */
export const toCreateAdViewPath = adAccountId => `/${adAccountId}/adview/create`
export const toAdViewManagementPath = adAccountId => `/${adAccountId}/adview`
export const toAdViewDetailPath = (adAccountId, adViewId) =>
  `/${adAccountId}/adview/${adViewId}`
export const toModifyAdViewPath = (adAccountId, adViewId) =>
  `/${adAccountId}/adview/${adViewId}/modify`

/**
 * message
 */
export const toMessageListPath = adAccountId => `/${adAccountId}/message`
export const toMessageCreatePath = adAccountId =>
  `/${adAccountId}/message/create`
export const toMessageDetailPath = (adAccountId, messageTemplateId) =>
  `/${adAccountId}/message/${messageTemplateId}`
export const toMessageModifyPath = (adAccountId, messageTemplateId) =>
  `/${adAccountId}/message/${messageTemplateId}/modify`

/**
 * talkBizForm
 */
export const toBizFormListPath = adAccountId =>
  `/${adAccountId}/linkage/talkbizform`

/**
 * catalog
 */
export const toCatalogPath = adAccountId =>
  `/${adAccountId}/catalog-manager/catalogs`

export const toCatalogDetailPath = (adAccountId, catalogId) =>
  `/${adAccountId}/catalog-manager/catalogs/${catalogId}`

export const toCatalogProductDetailPath = (adAccountId, catalogId, itemId) =>
  `/${adAccountId}/catalog-manager/catalogs/${catalogId}/products/${itemId}`

export const toCatalogProductSetPath = (adAccountId, catalogId, productSetId) =>
  `/${adAccountId}/catalog-manager/catalogs/${catalogId}/product-sets/${productSetId}`
